<template>
  <body>
    <main class="container">
      <Documents />
      <div v-if="currentTab === 'tab4'">
        <AdvancedSearchForm />
      </div>
    </main>
  </body>
</template>

<script>
import AdvancedSearchForm from "@/components/TBCADatabases/AdvancedSearchForm.vue";
import Documents from "@/components/TBCADatabases/Documents.vue";

const TABS = [];

export default {
  components: {
    AdvancedSearchForm,
    Documents,
  },

  data: () => ({
    tabs: TABS,
    currentTab: "tab1",
  }),

  methods: {
    handleClick(newTab) {
      this.currentTab = newTab;
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 600px) {
  body {
    width: 100%;

    main {
      width: auto;
    }
  }
}
body {
  padding: 1rem 0;
  background: linear-gradient(rgba(0, 0, 0, 0.467), rgba(0, 0, 0, 0.385)),#ddd;
}
</style>
