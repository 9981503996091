<template>
  <body class="add-padding team-member__profile">
    <main class="container">
      <aside>
        <br />
        <img src="../../assets/jos.png" alt="" />
        <div class="social-medias__team-profile">
          <a href="https://www.facebook.com/1earth4peace">
            <i class="fab fa-facebook"></i
          ></a>
          <i class="fab fa-instagram"></i>
          <i class="fab fa-linkedin"></i>
          <i class="fab fa-twitter"></i>
        </div>
      </aside>

      <article>
        <TeamMemberProfile name="Joseline Barakagwira" />
        <p>
          Joseline Barakagwira is a conservation biologist who
          graduated in Zoology and Conservation at the University of Rwanda (UR)
          in November, 2018.
        </p>
        <p>
          She has been an active member of an active member of Rwanda University
          Club for Conservation of biodiversity (RUCCB) for four years. Since
          2018, she works for the Center of Excellence in Biodiversity and
          Natural Resource Management (CoEB) as a volunteer.
        </p>
        <p>
          She holds a certificate in one health approaches awarded by One Health
          Central and East Africa. She has been trained in different aspects of
          community conservation by the Center,One Health Central and East
          Africa ( OHCEA) and Rwanda Environment Management Authority (REMA)
          through different collaborative projects. She is interested in human
          and non human primates interactions.
        </p>

        <a href="">
          <DownloadCv label="Download CV" />
        </a>
      </article>
    </main>
  </body>
</template>

<script>
import DownloadCv from "@/components/Models/DownloadCv.vue";
import TeamMemberProfile from "@/components/Models/TeamMemberProfile.vue";

export default {
  components: {
    DownloadCv,
    TeamMemberProfile,
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 600px) {
  body {
    width: 100%;
    padding: 10px;
    main {
      width: auto;
      flex-direction: column !important;
    }
    p {
      width: 100% !important;
    }
  }
}
</style>
