<template>
  <body class="add-padding team-member__profile">
    <main class="container">
      <aside>
        <br />
        <img src="../../assets/elaine.png" alt="" />
        <div class="social-medias__team-profile">
          <a href="https://www.facebook.com/1earth4peace">
            <i class="fab fa-facebook"></i
          ></a>
          <i class="fab fa-instagram"></i>
          <i class="fab fa-linkedin"></i>
          <i class="fab fa-twitter"></i>
        </div>
      </aside>

      <article>
        <TeamMemberProfile name="Elaine (Lan Yin) Hsiao" />
        <p>
          Elaine, also known as Lan Yin, is the
          <a href="">Global Challenges Fellow</a> at the Sheffield Institute for
          International Development <a href="">(SIID)</a> and is an
          interdisciplinary scholar and consultant, specializing in
          transboundary conservation areas, Indigenous and community
          conservation, and environmental peacebuilding.
        </p>
        <p>
          Elaine completed her PhD in Resource Management & Environmental
          Studies at the University of British Columbia's (UBC) Institute for
          Resources, Environment and Sustainability
          <a href="">(IRES)</a> and holds a JD and LLM in International and
          Environmental Law from <a href=""> Pace Law School</a>, where she
          remains a Fellow, Specializing in Protected Areas at the
          <a href=""> Global Center for Environmental Legal Studies</a>.
        </p>
        <p>
          She currently Co-Chairs the
          <a href="">IUCN CEESP Theme on Environment and Peace</a> , including
          its Task Force on Environmental Change and Migration, and is an
          Honorary Member of the <a href=""> ICCA Consortium</a>. Elaine is also
          a Research Affiliate with the Biodiversity and Security research
          project (BIOSEC) and an Honorary Research Fellow at the University of
          Rwanda's Center of Excellence in Biodiversity and Natural Resource
          Management <a href="">(UR CoEB)</a>. She has founded and led multiple
          initiatives and is an active member in local and global networks.
        </p>
        <p>
          Originally from northern California, Elaine loves camping and hiking
          in coastal areas and mountain forests, crafting (lately mostly sewing
          and crochet), slow foods and good stories over tea.
        </p>

        <a href="">
          <DownloadCv label="Download CV" />
        </a>
      </article>
    </main>
    <br />
    <EduBackground />
    <div class="elaine-quote">
      <i class="fas fa-quote-right"></i>
      <p>
        "When the power of Love overcomes the love of Power,
      </p>
      <p>the World will know Peace."</p>
      <br />
      <small>[Jimi Hendrix]</small>
    </div>
    <OtherProjects />
  </body>
</template>

<script>
import OtherProjects from "@/components/PersonalBlog/OtherProjects.vue";
import EduBackground from "@/components/PersonalBlog/EduBackground.vue";
import DownloadCv from "@/components/Models/DownloadCv.vue";
import TeamMemberProfile from "@/components/Models/TeamMemberProfile.vue";

export default {
  components: {
    OtherProjects,
    EduBackground,
    DownloadCv,
    TeamMemberProfile,
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 600px) {
  body {
    width: 100%;
    padding: 10px;
    main {
      width: auto;
      flex-direction: column !important;
    }
    p {
      width: 100% !important;
    }
  }
}

.elaine-quote {
  color: #fff;
  font-weight: 500;
  font-size: 30px;
  padding: 3% 0;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.712)),
    url("../../assets/cover.jpeg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  text-align: center;
  background-position: center;
}
</style>
