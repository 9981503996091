<template>
  <aside class="container">
    <h2>Tweets and Rreplies</h2>

    <div class="content">
      <blockquote class="twitter-tweet">
        <p lang="en" dir="ltr">
          <a href="https://twitter.com/biosec_erc?ref_src=twsrc%5Etfw"
            >@biosec_erc</a
          >
          has been the most stimulating and loving academic family I have had,
          probably since my summers at
          <a href="https://twitter.com/UPEACE?ref_src=twsrc%5Etfw">@UPEACE</a>
          it was a joy to see all these gorgeous faces, to reflect on whats been
          co-created, and hold hope for all that lies ahead!
          <a href="https://twitter.com/SIIDgroup?ref_src=twsrc%5Etfw"
            >@SIIDgroup</a
          >
          <a href="https://twitter.com/CoErwanda?ref_src=twsrc%5Etfw"
            >@CoErwanda</a
          >
          <a href="https://twitter.com/SheffSocScience?ref_src=twsrc%5Etfw"
            >@SheffSocScience</a
          >
          <a href="https://t.co/Vj2efiOgxf">https://t.co/Vj2efiOgxf</a>
        </p>
        &mdash; Elaine (Lan Yin) Hsiao (@1earth4peace)
        <a
          href="https://twitter.com/1earth4peace/status/1295957773054697478?ref_src=twsrc%5Etfw"
          >August 19, 2020</a
        >
      </blockquote>

      <blockquote class="twitter-tweet">
        <p lang="en" dir="ltr">
          Getting a taste of Taiwanese academia here at the Academia Sinica, the
          country&#39;s top research institute, hosting a conference on
          post-COVID 19 sustainability in Taiwan @SinicaFan
          <a href="https://twitter.com/SIIDgroup?ref_src=twsrc%5Etfw"
            >@SIIDgroup</a
          >
          <a href="https://twitter.com/CoErwanda?ref_src=twsrc%5Etfw"
            >@CoErwanda</a
          >
          <a href="https://twitter.com/IUCN_CEESP?ref_src=twsrc%5Etfw"
            >@IUCN_CEESP</a
          >
          <a href="https://twitter.com/IUCN?ref_src=twsrc%5Etfw">@IUCN</a>
          <a href="https://twitter.com/liuinstituteUBC?ref_src=twsrc%5Etfw"
            >@liuinstituteUBC</a
          >
          <a href="https://twitter.com/ubcires?ref_src=twsrc%5Etfw">@ubcires</a>
          <a href="https://twitter.com/HaubLawatPace?ref_src=twsrc%5Etfw"
            >@HaubLawatPace</a
          >
          <a href="https://twitter.com/EnvPeacebuild?ref_src=twsrc%5Etfw"
            >@EnvPeacebuild</a
          >
          <a href="https://t.co/x54yKr5Kgg">pic.twitter.com/x54yKr5Kgg</a>
        </p>
        &mdash; Elaine (Lan Yin) Hsiao (@1earth4peace)
        <a
          href="https://twitter.com/1earth4peace/status/1295956931173969920?ref_src=twsrc%5Etfw"
          >August 19, 2020</a
        >
      </blockquote>
      <blockquote class="twitter-tweet">
        <p lang="en" dir="ltr">
          Bravo
          <a href="https://twitter.com/BrittaSjo?ref_src=twsrc%5Etfw"
            >@BrittaSjo</a
          >
          et al for pulling together this issue on the ILC draft principles on
          protection of the envt in relation to armed conflict
          <a
            href="https://twitter.com/hashtag/PERAC?src=hash&amp;ref_src=twsrc%5Etfw"
            >#PERAC</a
          >. Excited to see some of my PhD morphed into a piece on Protecting
          PAs in bello, case studies from
          <a href="https://twitter.com/GreaterVirunga?ref_src=twsrc%5Etfw"
            >@GreaterVirunga</a
          >
          and
          <a href="https://twitter.com/Kidepo_NP?ref_src=twsrc%5Etfw"
            >@Kidepo_NP</a
          >
          <a href="https://t.co/j2zwlE2tJo">https://t.co/j2zwlE2tJo</a>
        </p>
        &mdash; Elaine (Lan Yin) Hsiao (@1earth4peace)
        <a
          href="https://twitter.com/1earth4peace/status/1286689612690124800?ref_src=twsrc%5Etfw"
          >July 24, 2020</a
        >
      </blockquote>
      <blockquote class="twitter-tweet">
        <p lang="en" dir="ltr">
          The piece is insightful, Laura. It triggers the need for more advanced
          law enforcement software systems to help airport authorities “act for”
          wildlife conservation—with the help of expert research initiatives.
          Hope to discuss more on this with you soon:)
        </p>
        &mdash; Musa S. Kanneh (@MusaSKanneh6)
        <a
          href="https://twitter.com/MusaSKanneh6/status/1295443434887696384?ref_src=twsrc%5Etfw"
          >August 17, 2020</a
        >
      </blockquote>
    </div>
  </aside>
</template>

<script>
export default {
  components: {},
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 900px) {
  aside {
    margin-bottom: 20px !important;
  }
}

aside {
  height: 500px;
  border: 1px solid #1010103d;
  overflow: auto;
  padding: 0 0.5rem;
  position: -webkit-sticky;
  position: sticky;
  width: 100%;
  background-color: #1ea1f2;

  h2 {
    text-align: center;
    color: #fbfbfb;
    position: -webkit-sticky;
    position: sticky;
    font-size: 16px;
    background: #1ea1f2;
    padding: 10px 0;
    top: 0;
  }
}
</style>
