<template>
  <body class="add-padding team-member__profile">
    <main class="container">
      <aside>
        <br />
        <img src="../../assets/kanneh1.jpg" alt="" />
        <div class="social-medias__team-profile">
          <a v-bind:href="Facebook"> <i class="fab fa-facebook"></i></a>
          <i class="fab fa-instagram"></i>
          <a v-bind:href="LinkedIn"> <i class="fab fa-linkedin"></i></a>
          <a v-bind:href="Twitter"> <i class="fab fa-twitter"></i></a>
        </div>
      </aside>

      <article>
        <p>
          <strong>Musa Kanneh</strong>
          is a senior Computer Science major (BSc) and a Conservation Scholar at
          the African Leadership University
          <a v-bind:href="ALULogo">(ALU)</a>
          School of Wildlife Conservation<a v-bind:href="SoWCLogo"> SoWC</a>
          in Kigali, Rwanda. Musa is a lifelong learner and tech enthusiast. He
          is driven by a desire to harness the power of Machine Learning for
          participatory wildlife monitoring based on open-source data in Africa.
          To this end, he is concurrently pursuing a twelve-month software
          engineering program designed by the African Leadership Xcelerator
          <a v-bind:href="ALX">ALX</a> and
          <a v-bind:href="HolbertonSchool"> Holberton School</a>, to further
          equip him with the requisite knowledge and skills to pursue his career
          goals.
        </p>
        <p>
          He is experienced in community-based conservation and protected areas
          management. He has interned and worked with institutions like
          <a v-bind:href="RwandaPolytechnic"> Rwanda Polytechnic</a>, the
          Sheffield Institute for International Development
          <a v-bind:href="SIID">SIID</a> and the
          <a v-bind:href="HoneyGuideFoundation">Honeyguide Foundation</a> in
          Arusha, Tanzania, where he developed financial assessment tools for
          tourism enterprises in four Wildlife Management Areas across Northern
          Tanzania.
        </p>
        <p>
          Musa also works as a software engineer at
          <a v-bind:href="AgriPredict">Agripredict</a>, a tech company that
          applies machine-learning to the agricultural sector to help farmers
          manage risk. Agripredict provides farmers in Zambia with access to
          information that helps them to identify diseases, predict pest
          infestations and weather conditions.
        </p>
        <p>
          Musa is a member of the IUCN Commission on Environmental, Economic and
          Social Policy
          <a v-bind:href="CEESP">(CEESP)</a>, where he contributes to the
          Commission’s Theme on Environment and Peace.
        </p>
        <p>
          He has developed and contributed to the maintenance of platforms like
          <a v-bind:href="WhispersOfTheWild">Whispers of the Wild</a>,
          <a v-bind:href="Bongalo">Bongalo</a>, <a v-bind:href="Zap">Zap</a>,
          <a v-bind:href="EcologicalPeace">Ecological Peace</a>, etc. When not
          writing codes, he enjoys travelling and exploring nature and its
          precious resources.
        </p>
        <a href="">
          <DownloadCv
            v-on:click.prevent="alertOnResumeDownlead()"
            label="Download CV"
          />
        </a>
      </article>
    </main>
  </body>
</template>

<script>
import DownloadCv from "@/components/Models/DownloadCv.vue";

export default {
  components: {
    DownloadCv,
  },

  data() {
    return {
      /**
       * Links to external websites
       */
      ALULogo: "https://www.alueducation.com/",
      SoWCLogo: "https://sowc.alueducation.com/",
      EcologicalPeace: "http://ecologicalpeace.org/",
      CEESP:
        "https://www.iucn.org/commissions/commission-environmental-economic-and-social-policy/our-work/environment-and-peace",
      AgriPredict: "https://agripredict.com/",
      WhispersOfTheWild: "http://www.whispersofthewild.com/",
      Zap: "https://www.zap.co.ke/",
      ALX: "https://www.alx.app/",
      HolbertonSchool: "https://www.holbertonschool.com/",
      RwandaPolytechnic: "https://rp.ac.rw/index.php?id=2",
      HoneyGuideFoundation: "https://www.honeyguide.org/",
      SIID: "http://siid.group.shef.ac.uk/",

      /**
       * Social meda links
       */
      Facebook: "https://www.facebook.com/profile.php?id=100007245843942",
      LinkedIn: "https://www.linkedin.com/in/musa-s-kanneh-0a47a514b/",
      Twitter: "https://twitter.com/MusaSKanneh6",
      Bongalo: "https://www.bongalo.co/",

      counter: 0,
      NoResumeAlert: "Resume will be available soon. Please check back later!",
    };
  },

  methods: {
    alertOnResumeDownlead() {
      alert(this.NoResumeAlert);
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 600px) {
  body {
    width: 100%;
    padding: 10px;
    main {
      width: auto;
      flex-direction: column !important;
    }
    p {
      width: 100% !important;
    }
  }
}
</style>
