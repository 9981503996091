<template>
  <body class="add-padding team-member__profile">
    <main class="container">
      <aside>
        <br />
        <img src="../../assets/beth.png" alt="" />

        <div class="social-medias__team-profile">
          <a href="https://www.facebook.com/profile.php?id=100007245843942">
            <i class="fab fa-facebook"></i
          ></a>
          <i class="fab fa-instagram"></i>
          <a href="https://www.linkedin.com/in/dan-brockington-9aa07513/">
            <i class="fab fa-linkedin"></i
          ></a>

          <a href="https://twitter.com/MusaSKanneh6">
            <i class="fab fa-twitter"></i
          ></a>
        </div>
      </aside>

      <article>
        <TeamMemberProfile name="Prof Beth Kaplin" />
        <p>
          Prof Kaplin was appointed Director of the Center of Excellence in
          Biodiversity &amp; Natural Resource Management at UR in 2016. This is
          a knowledge management research institute aimed at contributing
          science to policy in support of SDGs. Major achievements in the Center
          include raising funds to develop a freshwater biodiversity information
          system for Rwanda, upgrading and digitizing the National Herbarium of
          Rwanda, creating the guidelines for access and benefit sharing of
          traditional knowledge associated with plant genetic resources in
          Rwanda, and providing research capacity training for postgraduate
          students.
        </p>

        <p>
          Beth is a Professor of Conservation Science in the Biology Dept,
          College of Science &amp; Technology at University of Rwanda, and she
          teaches and mentors BSc, MSc and PhD students. She is also a Research
          Professor in the School for the Environment and Senior Fellow at the
          Center of Global Governance and Sustainability, both at University of
          Massachusetts-Boston. Beth was in the Environmental Studies Dept at
          Antioch University New England, USA for 20 years (1998-2018) where she
          served as PhD program director, developed curriculum, and founded and
          directed the Center for Tropical Ecology &amp; Conservation.
        </p>

        <p>
          She received her BSc in wildlife biology from Colorado State
          University, and MSc and PhD in Zoology from University of
          Wisconsin-Madison, USA with a focus on ecology and conservation
          science. From 2006 to 2015, Professor Kaplin raised over 1 million USD
          to develop BSc and MSc programs in biodiversity conservation at
          National University of Rwanda with funding from the MacArthur
          Foundation. She also created the Regional Network for Conservation
          Educators in the Albertine Rift to support and empower conservation
          scientists in Rwanda, Burundi, DRC, Tanzania, and Uganda. She
          maintains a research program with her students on forest ecology, seed
          dispersal, primates, protected areas conservation, ecosystem services,
          and human-wildlife interactions that began in 1990 when she first came
          to do research in Nyungwe forest, Rwanda.
        </p>

        <p>
          She has published numerous articles and book chapters on these topics.
          Her current projects include buffer zones and biodiversity indicators,
          primate ecology and conservation, and developing a biodiversity
          information system for effective policy-making and climate change
          adaptation. She has focused her career on building capacity for
          biodiversity conservation, research and natural resource management in
          Africa.
          <a href="https://bethkaplin.org/about-dr-kaplin-2/">My website</a>
        </p>

        <a href="">
          <DownloadCv label="Download CV" />
        </a>
      </article>
    </main>
  </body>
</template>

<script>
import DownloadCv from "@/components/Models/DownloadCv.vue";
import TeamMemberProfile from "@/components/Models/TeamMemberProfile.vue";

export default {
  components: {
    DownloadCv,
    TeamMemberProfile,
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 600px) {
  body {
    width: 100%;
    padding: 10px;

    main {
      width: auto;
      flex-direction: column !important;
    }
    p {
      width: 100% !important;
    }
  }
}
</style>
