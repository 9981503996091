<template>
  <body>
    <main class="container">
      <Tabs>
        <Tab
          name="Sheffield Institute for International Development"
          selected="true"
        >
          <div class="content">
            <img src="../../assets/eco.png" alt="" />
            <div>
              <strong>About SIID</strong>
              <p class="mobile__text">
                The Sheffield Institute for International Development (SIID) is
                a flagship interdisciplinary research institute within the
                University of Sheffield. Our network includes a growing
                fellowship of over 100 scholars and hundreds of postgraduate
                students from across the University, in addition to a wider
                community of academic and practitioner partners in governments,
                think tanks and NGOs around the world.
              </p>
              <strong>Our vision</strong>
              <p>
                We strive for social and environmental justice in a prosperous
                world. We insist that social, economic, political, and
                environmental injustices cannot be resolved, nor prosperity
                found, simply from economic growth. We seek solutions to these
                problems that are based on partnerships which transcend
                disciplines, institutions and borders.
              </p>
              <strong>Our mission</strong>
              <p>
                We foster bespoke eye-level partnerships across the University,
                the UK and overseas, with colleagues inside and outside
                academia, that are founded on principles of respect, support and
                mutual benefit. Our key aims are to encourage
                interdisciplinarity and foster research which reaches beyond the
                academe. To that end we convene both small-scale and flagship
                events to bring together people and organisations. We provide
                seed-corn funding for new projects. We are building a research
                community of newly qualified and experienced post-doctoral
                researchers in Sheffield. We are fostering a set of key national
                and international partnerships so that we can rapidly respond to
                new challenges and opportunities.
              </p>
            </div>
          </div>
        </Tab>
        <Tab name="University of Rwanda Center of Biodiversity and Excellence">
          <div class="content">
            <img src="../../assets/coeb-logo.png" alt="" />
            <div class="outcome">
              <p>
                The Center of Excellence in Biodiversity and Natural Resources
                Management (CoEB) is a knowledge management research center
                located at the University of Rwanda (UR). It operates as a
                consortium of governmental and non-governmental
                environment-related research and higher learning institutions in
                Rwanda called nodes, coordinated by the central hub office at UR
                in the College of Science and Technology.
              </p>
              <p>
                Capacity for scientific research for the environment,
                biodiversity and sustainable use of natural resources is limited
                in the region. Recognizing the importance of science‐based
                decision-making for economic transformation and sustainable
                development goals, the President of the Republic of Rwanda, H.E
                Paul Kagame proposed the establishment of the CoEB during the
                2007 International Conference on Biodiversity & Sustainable
                Management of Natural Resources in Kigali. The Ministries of
                Education and Natural Resources under the auspices of UNESCO
                together created the framework to establish this Center. Located
                in the Albertine Rift and Great Lakes region, a biodiversity
                hotspot, this Center is now poised to contribute significantly
                to coordinating biodiversity information in Rwanda and the
                region.
              </p>
              <p>
                The Center supports the understanding, monitoring and
                cataloguing of biodiversity, ecosystem functioning, and climate
                change impacts and adaptation strategies, as well as
                communication and information sharing for effective conservation
                through science-driven decision making. The aim is to contribute
                to Rwanda’s economic transformation goals. The Center will
                become a UNESCO category 2 Center in the near future,
                contributing to regional biodiversity science and conservation
                for the region.
              </p>
            </div>
          </div>
        </Tab>
      </Tabs>
    </main>
  </body>
</template>

<script>
import Tab from "@/components/Tabs/Tab.vue";
import Tabs from "@/components/Tabs/Tabs.vue";
export default {
  components: {
    Tab,
    Tabs,
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 900px) {
  .content {
    display: block !important;
  }
  .mobile__text {
    font-size: 14px !important;
  }
}

body {
  padding: 2rem 0;

  .content {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 2rem;

    img {
      width: 100%;
    }

    p {
      margin: auto;
      font-size: 16px !important;
      line-height: 1.5;
      margin: 1rem 0;
    }
  }

  h2,
  strong,
  h3 {
    color: #689d1a;
  }
}
</style>
