<template>
  <section class="other-projects-content">
    <main class="container">
      <Captions caption="Other Projects"></Captions>
      <section class="activities-sec-cover">
        <article>
          <tabs
            :tabs="tabs"
            :currentTab="currentTab"
            :wrapper-class="'default-tabs'"
            :tab-class="'default-tabs__item'"
            :tab-active-class="'default-tabs__item_active'"
            :line-class="'default-tabs__active-line'"
            @onClick="handleClick"
          />
          <div class="content">
            <div v-if="currentTab === 'tab1'" class="current-tab-sec">
              <div class="tm-how-content">
                <section class="sec-cover">
                  <div class="work-inner">
                    <aside><img src="../../assets/siid.png" alt="" /></aside>
                    <article>
                      <a
                        href="https://www.iucn.org/commissions/commission-environmental-economic-and-social-policy/our-work/environment-and-peace "
                        >CEESP Theme on Environment + Peace</a
                      >
                      <p>
                        The Sheffield Institute for International Development
                        (SIID) is a flagship interdisciplinary research
                        institute within the University of Sheffield. Our
                        network includes a growing fellowship of over 100
                        scholars and hundreds of postgraduate students from
                        across the University, in addition to a wider community
                        of academic and practitioner partners in governments,
                        think tanks and NGOs around the world.
                      </p>
                      <a href="/institutions">Read More</a>
                    </article>
                  </div>
                  <div class="work-inner">
                    <aside>
                      <img src="../../assets/biosec.png" alt="" />
                    </aside>
                    <article>
                      <a href="https://biosecproject.org/"
                        >Biodivesity and Security</a
                      >
                      <p>
                        Elaine is a
                        <small>
                          <a
                            href="https://biosecproject.org/team/elaine-lan-yin-hsiao/"
                            >Research Fellow</a
                          ></small
                        >
                        with the four-year Biodiversity and Security Research
                        Project (BIOSEC), funded by a EURO 1.8million European
                        Research Council (ERC) Advanced Investigator Grant and
                        led by Professor Rosaleen Duffy.
                      </p>
                    </article>
                  </div>
                </section>
              </div>
            </div>
            <div v-if="currentTab === 'tab2'" class="current-tab-sec">
              <div class="tm-how-content">
                <div class="work-inner">
                  <aside><img src="../../assets/ceesp.png" alt="" /></aside>
                  <article>
                    <p>
                      IUCN is a membership Union composed of both government and
                      civil society organisations. It harnesses the experience,
                      resources and reach of its more than
                      <a href="https://www.iucn.org/about/union/members"
                        >1,400 Member organisations</a
                      >
                      and the input of more than
                      <a href="https://www.iucn.org/about/union/commissions"
                        >15,000 experts.</a
                      >
                      This diversity and vast expertise makes IUCN the global
                      authority on the status of the natural world and the
                      measures needed to safeguard it.
                    </p>
                    <p>
                      The six IUCN Commissions are broad and active networks of
                      scientists and experts providing IUCN and its Members with
                      sound know-how and policy advice to drive conservation and
                      sustainable development.
                    </p>
                    <a
                      href="https://www.iucn.org/commissions/commission-environmental-economic-and-social-policy/our-work/environment-and-peace "
                      >CEESP Theme on Environment + Peace</a
                    >
                    <p>
                      The Commission on Environmental, Economic and Social
                      Policy
                      <a
                        href="https://www.iucn.org/commissions/commission-environmental-economic-and-social-policy/our-work/environment-and-peace "
                        >(CEESP)</a
                      >
                      contributes to the IUCN's Mission by generating and
                      disseminating knowledge, mobilising influence, and
                      promoting actions to harmonise the conservation of nature
                      with the critical social, cultural, environmental, and
                      economic justice concerns of human societies.
                    </p>
                    <p>
                      Elaine Co-Chairs the IUCN CEESP Theme on Environment and
                      Peace, which focuses on the integration of natural
                      resource management in conflict prevention, mitigation,
                      resolution, and recovery to build resilience in
                      communities affected by conflict.
                    </p>
                    <p>
                      Under the IUCN CEESP Theme on Environment and Peace,
                      Elaine established a Task Force on Migration,
                      Environmental Change and Conflict with colleagues
                      <a href="https://faculty.sites.uci.edu/rmatthew/"
                        >Richard Matthew</a
                      >
                      and
                      <a href="https://galeosaintz.com/">Galeo Saintz.</a> This
                      Task Force convenes interdisciplinary and cross-sectoral
                      experts to enhance knowledge on interlinkages, drivers and
                      impacts of forced migration of humans and other species at
                      the intersection of environmental change and violent
                      conflict.
                    </p>

                    <a
                      href="https://www.youtube.com/watch?v=fMS4b8YOaSo#action=share "
                      >United Nations EMG Nexus Dialogue on Biodiversity and
                      Human Security:
                    </a>
                    <p>
                      Elaine is a member of the IUCN Species Survival Commission
                      (SSC) Human-Wildlife Conflict Task Force. The IUCN SSC
                      Human-Wildlife Conflict Task Force is a global advisory
                      group of experts from a range of subjects working on
                      interdisciplinary approaches to wildlife conservation. The
                      Task Force supports the IUCN network, conservation
                      practitioners and international organisations around the
                      world with guidance and information on best practice in
                      this field. <a href="http://www.hwctf.org/ ">More here</a>
                    </p>
                  </article>
                </div>
              </div>
            </div>
            <div v-if="currentTab === 'tab3'" class="current-tab-sec">
              <div class="tm-how-content">
                <div class="work-inner">
                  <aside><img src="../../assets/iccs.png" alt="" /></aside>

                  <article>
                    <a href="https://www.iccaconsortium.org/ "
                      >ICCA Consortium</a
                    >
                    <p>
                      The ICCA Consortium grew out of the movement promoting
                      equity in conservation in the decades around the turn of
                      the Millennium. It is a membership-based civil society
                      organisation supported by an international semi-volunteer
                      Secretariat based in twenty-two countries. Today,
                      custodian communities and their ICCAs face unprecedented
                      stresses and threats arising from the socio-ecological
                      changes sweeping the world. Increasingly, however, they
                      are also being recognised as among the best hopes for the
                      conservation of biological and cultural diversity left on
                      the planet. The ICCA Consortium is responding to threats
                      and opportunities by actively upholding ICCAs and
                      promoting ICCA self-strengthening processes, along with
                      networking, peer-support and advocacy at sub-national,
                      national and international levels. Elaine is an Honorary
                      Member of the ICCA Consortium. The Honorary Members of the
                      Consortium are nominated individuals with remarkable
                      experience, concerns and capacities to support CCAs.
                    </p>
                  </article>
                </div>
              </div>
            </div>
            <div v-if="currentTab === 'tab4'" class="current-tab-sec">
              <div class="tm-how-content">
                <div class="work-inner">
                  <aside><img src="../../assets/urceod.png" alt="" /></aside>

                  <article>
                    <a
                      href="https://www.iucn.org/commissions/commission-environmental-economic-and-social-policy/our-work/environment-and-peace "
                      >CEESP Theme on Environment + Peace</a
                    >
                    <p>
                      The Center of Excellence in Biodiversity and Natural
                      Resources Management (CoEB) is a knowledge management
                      research center located at the University of Rwanda (UR).
                      It operates as a consortium of governmental and
                      non-governmental environment-related research and higher
                      learning institutions in Rwanda called nodes, coordinated
                      by the central hub office at UR in the College of Science
                      and Technology.
                    </p>
                    <a href="/institutions">Read More</a>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </article>
      </section>
    </main>
    <br />
  </section>
</template>

<script>
import Tabs from "vue-tabs-with-active-line";
import Captions from "@/components/Captions";
const TABS = [
  {
    title: "CEESP & BIOSEC",
    value: "tab1",
  },
  {
    title: "IUCN ",
    value: "tab2",
  },
  {
    title: "ICCA Constrotuns",
    value: "tab3",
  },
  {
    title: "CoEB",
    value: "tab4",
  },
];

export default {
  components: {
    Tabs,
    Captions,
  },

  data: () => ({
    tabs: TABS,
    currentTab: "tab1",
  }),

  methods: {
    handleClick(newTab) {
      this.currentTab = newTab;
    },
  },
};
</script>

<style lang="scss">
@media (max-width: 600px) {
  .other-projects-content {
    width: 100%;

    main {
      width: auto;
    }

    .sec-cover {
      display: flex;
      flex-flow: column;
    }

    .work-inner {
      display: flex;
      flex-flow: column;
    }
  }
}

.other-projects-content {
  width: 100%;

  .current-tab-sec {
    display: flex;
  }

  .sec-cover {
    display: flex;
    justify-content: space-between;
  }

  .tm-image {
    width: 100%;
  }

  .content-cover {
    a {
      font-size: 13px;
      color: #6ca714;

      &:hover {
        color: green;
        transition: 0.5s ease;
      }
    }
  }

  .work-inner {
    width: auto;
    padding: 10px;
    box-shadow: 0 0 5px 0.5px rgba(0, 0, 0, 0.123);
    background-color: #fff;
    cursor: pointer;
    display: flex;

    img {
      width: 90%;
    }

    aside {
      flex: 1;
    }

    article {
      flex: 4;
      height: auto;
      padding: 0 10px;

      p {
        font-weight: 400;

        i {
          color: #6ca714;
        }
      }

      a {
        color: #6ca714;
        font-size: 15px;
        font-weight: 500;
        text-decoration: none;
      }
      a:hover {
        text-decoration: underline;
        color: #6ca714;
      }
    }
  }

  p {
    width: 90%;
  }

  .tm-how-content {
    width: 100%;
  }

  .tm-image {
    width: 30%;
  }
  .activities-sec-cover {
    display: flex;

    img {
      width: 100%;
    }
  }

  .default-tabs {
    background: #6ca714;
    -webkit-box-shadow: 0px 2px 15px -12px rgba(142, 196, 42, 0.57);
    -moz-box-shadow: 0px 2px 15px -12px rgba(105, 216, 15, 0.884);
    box-shadow: 1px 1px 5px rgba(16, 177, 16, 0.57);
    box-shadow: inset 0 -0.6em 1em -0.35em rgba(0, 0, 0, 0.17),
      inset 0 0.6em 2em -0.3em rgba(255, 255, 255, 0.15),
      inset 0 0 0em 0.05em rgba(255, 255, 255, 0.12);
    width: 100%;
    position: -webkit-sticky !important;
    position: sticky !important;
    width: 90%;
    position: relative;
    margin: 0 auto;

    &__item {
      display: inline-block;
      margin: 0 5px;
      padding: 10px;
      padding-bottom: 8px;
      font-size: 16px;
      letter-spacing: 0.8px;
      text-decoration: none;
      border: none;
      background-color: transparent;
      border-bottom: 2px solid transparent;
      cursor: pointer;
      font-weight: bold;
      transition: all 0.25s;

      &_active {
        color: #ffffff;
      }

      &:hover {
        color: #ffffff;
        transition: 1s ease;
        background-color: green;
      }

      &:focus {
        outline: none;

        color: #ffffff;
        background-color: green;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
    
    &__active-line {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 2px;
      background-color: #ffffff;
      transition: transform 0.4s ease, width 0.4s ease;
    }
  }
  .content {
    margin-top: 30px;
    font-size: 20px;
    width: 90%;
    margin: auto;
    border: 1px solid #6ca714;
  }
}
</style>
