<template>
  <body class="container">
    <main>
      <div class="protected-areas-list">
        <img src="../../assets/landscape.jpeg" alt="" />
        <h4>{{ name }}</h4>
      </div>
    </main>
  </body>
</template>

<script>
export default {
  computed: {},

  props: {
    title: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
body {
  .protected-areas-list {
    background-color: #ffffff;
    width: 100%;
    width: 95%;
    cursor: pointer;
    object-fit: contain;
    padding-bottom: 0.5rem;
    order: 2;
    background-color: #FFFFFF;
    -webkit-box-shadow: 0px 2px 15px -12px rgba(0, 0, 0, 0.57);
    -moz-box-shadow: 0px 2px 15px -12px rgba(0, 0, 0, 0.57);
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.57);
    img {
      width: 100%;
    }
  }

  h4 {
    font-size: 14px;
    padding: 0 1rem;
    font-weight: 500;
  }
}
</style>
