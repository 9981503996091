<template>
  <body>
    <AdvancedSearchOptions />
  </body>
</template>

<script>
import AdvancedSearchOptions from "@/components/TBCADatabases/AdvancedSearchOptions.vue";

export default {
  components: {
    AdvancedSearchOptions,
  },
};
</script>

<style lang="scss" scoped>
body {
  .notice-sec {
    text-align: center;
  }
}
</style>
