<template>
  <body class="container">
    <div>
      <h1>Past events</h1>
      <p>List of past events</p>
    </div>
    <main class="search-cover">
      <div>
        <h3>Virtual Presentation</h3>
        <p>
          Challenges Fellow, Elaine (Lan Yin) Hsiao presented on “Greater
          Virunga Transboundary Collaboration: Treaties for Mountain Gorilla's”
          in upcoming SADC TFCA Webinar on “The legal frameworks for
          transboundary species conservation”.
        </p>
        <a
          href="https://us04web.zoom.us/meeting/register/upAtdeyhrjMvE9SRX2u3yvfEW1zWJUdr80A9"
          >Find event link here</a
        >
      </div>
    </main>
  </body>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
body {
  .search-cover {
    //border: 1px solid red;
  }
}
</style>
