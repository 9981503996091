<template>
  <div class="team__cover">
    <img :src="img" />
    <h3>{{ name }}</h3>
    <p><i class="fa fa-user-tag"></i> {{ role }}</p>
  </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },

  props: {
    name: {
      type: String,
      required: true,
    },
    role: {
      type: String,
      required: true,
    },

    img: {
      type: Image,
    },
  },
};
</script>

<style lang="scss" scoped>
i {
  color: #1010107c;
}

h3,
p {
  margin: 0;
  padding: 0.5rem 0;
  color: #10101096;
}
</style>
