<template>
  <body class="container">
    <div class="partnership-sec wrapper">
      <div class="img-cover main">
        <img src="../../assets/r2.jpg" alt="" />
      </div>
      <div class="offers-cover-sec-1 main">
        <strong>
          From disrupted to emblematic ICCAs: <br />
          Revitalizing community conservation in transboundary landscapes of the
          Albertine Rift.
        </strong>
        <p>
          The Albertine Rift, a biodiversity hotspot, represents a confluence of
          all of the world’s peace and development challenges in ecologically
          unique and unforgettably beautiful landscapes with an equally rich
          diversity of cultures. This project is premised on the belief that it
          is possible to build positive peace between people and with nature
          alongside
          <span> regenerative development </span>without compromising cultural
          heritage in the Albertine, or anywhere.
        </p>
        <p>
          The story of <span>conservation in Africa</span> has historically been
          externally-driven, looking at colonial game reserves and parks as
          examples of a North Atlantic approach to fortress-model conservation
          implemented in tribal wildlands. In reality, conservation was embedded
          in many cultures across these landscapes, but these knowledge systems
          and practices have not been well-protected against political,
          economic, and social changes in the last decades (and arguably, hence,
          has resulted in significant environmental change).
        </p>
        <p>
          The role of traditional and local communities in modern African states
          is uncertain and even long-recognized communal lands are fragmenting
          into fenced private properties. Yet, if conservation is left to a few
          state authorities in sequestered parklands, the state of the
          environment will only continue to decline. It is imperative that
          conservation take root outside and in between protected areas, ie
          connectivity and for systems of environmental governance and
          stewardship to be driven by people and not just a regime of
          paramilitaries and penalties that may or may not be effective. These
          are the issues that underlie my motivation to focus this research on
          <span> community conservation and its revitalization.</span>
        </p>
        <router-link class="nav-button" to="/institutions">
          <Button
            :isFullWidth="false"
            label="Browse more"
            width="150px"
          ></Button>
        </router-link>
      </div>
    </div>
  </body>
</template>

<script>
import Button from "@/components/Button.vue";
export default {
  components: {
    Button,
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 600px) {
  body {
    width: 100%;

    main {
      width: auto;
    }

    main .partnership-sec {
      display: grid;
      grid-template-columns: 1fr;
    }

    .image-cover img {
      order: 1;
    }
  }
}

body {
  span {
    color: #689d1a;
  }

  .partnership-sec {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 20px;
    padding: 4rem 0;
  }

  p {
    margin: 0.5rem 0;
  }
  .img-cover img {
    width: 100%;
  }
}
</style>
