<template>
  <body>
    <main>
      <div class="grid-temps">
        <ProfileCard label="Africa" totalTBCA="10,00" />
        <ProfileCard label="Africa" totalTBCA="10,00" />
        <ProfileCard label="Africa" totalTBCA="10,00" />
        <ProfileCard label="Africa" totalTBCA="10,00" />
      </div>
    </main>
    <br /><br /><br /><br />
  </body>
</template>

<script>
import ProfileCard from "@/components/Models/TBCA/ProfileCard.vue";

export default {
  components: { ProfileCard },

  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 600px){
  body{
    padding: 10px;
    main .grid-temps {
      display: flex;
      flex-direction: column;
    }

  }
}

body {
  main .grid-temps{
    display: flex;
    justify-content: space-around;
    // margin-top: 1rem;
    // display: grid;
    // grid-template-columns: 1fr 1fr 1fr 1fr;
    // grid-gap: 15px;
  }
}
</style>
