<template>
  <div>
    <h2>{{ name }}</h2>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
h2{
    // margin: 0;
}
</style>
