<template>
  <body>
    <main>
      <div>
        <img src="../../../assets/map-img.png" alt="" />
      </div>
      <div>
        <h3>{{ label }}</h3>
      </div>
      <div>
        <p>{{ totalTBCA }} Transbountry Cnnservation Areas</p>
      </div>
    </main>
  </body>
</template>

<script>
export default {
  data() {
    return {};
  },

  props: {
    label: {
      type: String,
      required: true,
    },

    totalTBCA: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
body {
  border: 1px solid #ddd;
  background-color: #f4f4f4;
  -webkit-box-shadow: 1px 3px 4px 1px rgb(102, 102, 102);
  -moz-box-shadow: 3px 2px 7px -12px rgb(85, 85, 85);
  box-shadow: 1px 2px 2px rgb(211, 208, 208);
  cursor: pointer;

  main {
    padding: 1rem;

    img {
      width: 100%;
      border: 1px solid rgba(221, 221, 221, 0.603);
    }
  }
}
</style>
