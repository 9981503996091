<template>
  <body>
    <div class="tbca-profile__header-content">
      <h1 class="container">
        Explore the Transboundry Conservation Areas <br />
        and Knowledge System
      </h1>
    </div>
    <main class="container">
      <div class="display-options">
        <p v-on:click="handleShowRegion()">Regions</p>
        <p v-on:click="handleChangeCountry()">Countries</p>
        <p v-on:click="handleShowIndividual()">Individuals TBCA</p>
      </div>

      <div class="display__content-layout">
        <RedionalTBCAs v-if="showRegion" />
        <Countries v-else-if="showCountry" />
        <IndividualTBCAs v-else-if="showIndividual" />
        <RedionalTBCAs v-else />
      </div>
    </main>
  </body>
</template>

<script>
import Countries from "@/components/TBCADatabases/Country/Countries.vue";
import IndividualTBCAs from "@/components/TBCADatabases/Individual/IndividualTBCAs.vue";
import RedionalTBCAs from "@/components/TBCADatabases/Regions/RedionalTBCAs.vue";

export default {
  components: {
    Countries,
    IndividualTBCAs,
    RedionalTBCAs,
  },

  data() {
    return {
      showRegion: false,
      showCountry: false,
      showIndividual: false,
    };
  },

  methods: {
    async handleShowRegion() {
      this.showRegion = true;
    },

    async handleChangeCountry() {
      this.showCountry = true;
    },

    async handleShowIndividual() {
      this.showIndividual = true;
    },
  },
};
</script>

<style lang="scss" scoped>

@media (max-width: 600px){
  * {
    width: 100%;
  }
  body{
    .display-options {
      padding: 10px;
      display: flex;
      flex-direction: column;
    }

  }
}
body {
  .display-options {
    display: flex;

    p {
      cursor: pointer;
      border: 1px solid #6ca714;
      border-radius: 0.3rem;
      margin-right: 1rem;
      padding: 0.5rem 1rem;
    }
  }

  .tbca-profile__header-content {
    margin-bottom: 1rem;
    padding: 3rem 2.5rem;
    background-color: #6ca71475;

    h1 {
      font-weight: 600;
      font-size: 2.25rem !important;
      line-height: 3.2rem;
    }
  }
}
</style>
