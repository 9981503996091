import Vue from "vue";
import VueRouter from "vue-router";

import Test from "../views/Test";

/**
 * Home and about us
 */
import Home from "../views/PersonalBlog/Home";
import Institutions from "../views/PersonalBlog/Institutions";
import Project from "../views/PersonalBlog/Project";
import Writings from "../views/PersonalBlog/Writings";
import Media from "../views/PersonalBlog/Media";
import Gallery from "../views/PersonalBlog/Gallery";
import Articles from "../views/PersonalBlog/Articles";

/**
 * Connect with us
 */
import Contact from "../views/PersonalBlog/Contact";

/**
 * The team
 */
import Team from "../views/PersonalBlog/Team";
import Elaine from "../views/PersonalBlog/Elaine";
import Musa from "../views/PersonalBlog/Musa";
import Joseline from "../views/PersonalBlog/Joseline";
import Dorine from "../views/PersonalBlog/Dorine";
import Dan from "../views/PersonalBlog/Dan";
import Rosaleen from "../views/PersonalBlog/Rosaleen";
import Beth from "../views/PersonalBlog/Beth";
import Protais from "../views/PersonalBlog/Protais";

/**
 * The TBCA Database
 */
import Database from "../views/TBCADatabase/Database";
import SubmitLegalDoc from "../views/TBCADatabase/SubmitLegalDoc";
import LegalDocuments from "../views/TBCADatabase/LegalDocuments";
import TBCAInfo from "../views/TBCADatabase/TBCAInfo";
/**
 * Profiles
 */
import TBCAProfile from "../views/TBCADatabase/TBCAProfile"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "EP-Global | Homes,",
    },
  },
  {
    path: "/test",
    name: "test",
    component: Test,
  },
  {
    path: "/elaine",
    name: "elaine",
    component: Elaine,
  },
  {
    path: "/writings",
    name: "Writings",
    component: Writings,
  },
  {
    path: "/media",
    name: "Projects",
    component: Media,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/gallery",
    name: "Gallery",
    component: Gallery,
  },
  {
    path: "/submitlegaldoc",
    name: "submitlegaldoc",
    component: SubmitLegalDoc,
  },
  {
    path: "/project",
    name: "project",
    component: Project,
  },
  {
    path: "/institutions",
    name: "institutions",
    component: Institutions,
  },
  {
    path: "/articles",
    name: "articles",
    component: Articles,
  },
  {
    path: "/about",
    name: "about",
    component: Team,
  },
  {
    path: "/musa",
    name: "musa",
    component: Musa,
  },
  {
    path: "/joseline",
    name: "joseline",
    component: Joseline,
  },
  {
    path: "/dorine",
    name: "dorine",
    component: Dorine,
  },
  {
    path: "/dan",
    name: "dan",
    component: Dan,
  },
  {
    path: "/rosaleen",
    name: "rosaleen",
    component: Rosaleen,
  },
  {
    path: "/beth",
    name: "beth",
    component: Beth,
  },
  {
    path: "/protais",
    name: "protais",
    component: Protais,
  },

  /**
   * The TBCA Database routing starts
   */
  {
    path: "/database",
    name: "database",
    component: Database,
  },
  {
    path: "/legalDocs",
    name: "legalDocuments",
    component: LegalDocuments,
  },
  {
    path: "/info",
    name: "TBCAInfo",
    component: TBCAInfo,
  },
  {
    path: "/profiles",
    name: "profiles",
    component: TBCAProfile,
  },
  /**
   * The TBCA Database routing ends
   */
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
