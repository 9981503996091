<template>
  <div>
    <h1 :style="style">
      {{ caption }}
    </h1>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  props: {
    caption: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 900px) {
  h1 {
    font-size: 1.5em !important;
    font-weight: 500 !important;
  }
}

h1 {
  text-align: center;
  font-weight: 800;
  font-size: 2.2rem;
}
</style>
