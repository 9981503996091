<template>
  <div class="home">
    <!-- <HeaderCardMobile v-if="isMobile()" /> -->
    <HeaderCard />
    <!-- <AboutMobile v-if="isMobile()" /> -->
    <AboutCard />
    <GalleryCard />
  </div>
</template>

<script>
import HeaderCard from "@/components/PersonalBlog/HeaderCard";
import GalleryCard from "@/components/PersonalBlog/GalleryCard";
import AboutCard from "@/components/PersonalBlog/AboutCard";

export default {
  components: {
    HeaderCard,
    AboutCard,
    GalleryCard,
  },
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
