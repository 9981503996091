<template>
  <body class="other-projects-content-main">
    <hr />
    <section class="activities-sec-cover">
      <article class="container">
        <tabs
          :tabs="tabs"
          :currentTab="currentTab"
          :wrapper-class="'default-tabs'"
          :tab-class="'default-tabs__item'"
          :tab-active-class="'default-tabs__item_active'"
          :line-class="'default-tabs__active-line'"
          @onClick="handleClick"
        />
        <div class="container">
          <div v-if="currentTab === 'tab1'" class="current-tab-sec">
            <UpcommingEvents />
          </div>

          <div v-if="currentTab === 'tab3'" class="current-tab-sec">
            <div class="tm-how-content">
              <AdvancedSearchForm />
            </div>
          </div>

          <div v-if="currentTab === 'tab4'" class="current-tab-sec">
            <div class="tm-how-content">
              <PastEvents />
            </div>
          </div>
        </div>
      </article>
    </section>
  </body>
</template>

<script>
import Tabs from "vue-tabs-with-active-line";
import UpcommingEvents from "@/components/PersonalBlog/Events/UpcommingEvents.vue";
import PastEvents from "@/components/PersonalBlog/Events/PastEvents.vue";

const TABS = [
  {
    title: "Upcomming events",
    value: "tab1",
  },

  {
    title: "Past events",
    value: "tab4",
  },
];

export default {
  components: {
    Tabs,
    UpcommingEvents,
    PastEvents,
  },

  data: () => ({
    tabs: TABS,
    currentTab: "tab1",
  }),

  methods: {
    handleClick(newTab) {
      this.currentTab = newTab;
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 600px) {
  * {
    width: auto;
  }
}
.other-projects-content-main {
  .default-tabs {
    -webkit-box-shadow: 0px 2px 15px -12px rgba(142, 196, 42, 0.57);
    -moz-box-shadow: 0px 2px 15px -12px rgba(105, 216, 15, 0.884);
    box-shadow: 1px 1px 5px rgba(16, 177, 16, 0.57);
    box-shadow: inset 0 -0.6em 1em -0.35em rgba(0, 0, 0, 0.17),
      inset 0 0.6em 2em -0.3em rgba(255, 255, 255, 0.15),
      inset 0 0 0em 0.05em rgba(255, 255, 255, 0.12);
    width: 100%;
    position: -webkit-sticky !important;
    position: sticky !important;
    position: relative;
    margin: 0 auto;

    &__item {
      display: inline-block;
      margin: 0 5px;
      padding: 10px;
      padding-bottom: 8px;
      font-size: 16px;
      letter-spacing: 0.8px;
      text-decoration: none;
      border: none;
      background-color: transparent;
      border-bottom: 2px solid transparent;
      cursor: pointer;
      font-weight: bold;
      transition: all 0.25s;

      &_active {
        color: #ffffff;
      }

      &:hover {
        color: #ffffff;
        transition: 1s ease;
        background-color: green;
      }

      &:focus {
        outline: none;

        color: #ffffff;
        background-color: green;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    &__active-line {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 2px;
      background-color: #ffffff;
      transition: transform 0.4s ease, width 0.4s ease;
    }
  }
}
</style>
