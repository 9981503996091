<template>
  <div>
    <GmapMap
      :center='center'
      :zoom='5'
      ref="gmap"
      style='width:100%;  height: 700px;'
    />
  </div>
</template>

<script>
import { gmapApi } from 'vue2-google-maps'

export default {
  name: 'GoogleMap',
  data() {
    return {
      center: { lat: -0.028395316545239642, lng: 35.62625228384633 },
    }
  },

  computed: {
    google: gmapApi
  },
  mounted() {
    this.$refs.gmap.$mapPromise.then((map) => {
        const kmlLayer = new this.google.maps.KmlLayer({
            map,
            url: 'https://privfile.com/download.php?fid=60dc648599005-ODUxMQ=='
        })
        const kmlLayer1 = new this.google.maps.KmlLayer({
            map,
            url: 'https://privfile.com/download.php?fid=60dc648599005-ODUxMg=='
        })
        const kmlLaye2 = new this.google.maps.KmlLayer({
            map,
            url: 'https://privfile.com/download.php?fid=60dc648599005-ODUxMw=='
        })
        const kmlLaye3 = new this.google.maps.KmlLayer({
            map,
            url: 'https://privfile.com/download.php?fid=60dc648599005-ODUxNA=='
        })
        const kmlLaye4 = new this.google.maps.KmlLayer({
            map,
            url: 'https://privfile.com/download.php?fid=60dc648599005-ODUxNQ=='
        })
        const kmlLaye5 = new this.google.maps.KmlLayer({
            map,
            url: 'https://privfile.com/download.php?fid=60dc70df05476-ODUxOQ=='
        })
        const kmlLaye6 = new this.google.maps.KmlLayer({
            map,
            url: 'https://privfile.com/download.php?fid=60dc70df05476-ODUyMA=='
        })
        const kmlLaye7 = new this.google.maps.KmlLayer({
            map,
            url: 'https://privfile.com/download.php?fid=60dc70df05476-ODUyMQ=='
        })
        const kmlLaye8 = new this.google.maps.KmlLayer({
            map,
            url: 'https://privfile.com/download.php?fid=60dc70df05476-ODUyMg=='
        })
        const kmlLaye9 = new this.google.maps.KmlLayer({
            map,
            url: 'https://privfile.com/download.php?fid=60dc70df05476-ODUyMw=='
        })
        const kmlLaye10 = new this.google.maps.KmlLayer({
            map,
            url: 'https://privfile.com/download.php?fid=60dc70df05476-ODUyNA=='
        })
        const kmlLaye11 = new this.google.maps.KmlLayer({
            map,
            url: 'https://privfile.com/download.php?fid=60dc70df05476-ODUyNQ=='
        })
        const kmlLaye12 = new this.google.maps.KmlLayer({
            map,
            url: 'https://privfile.com/download.php?fid=60dc70df05476-ODUyNg=='
        })
        const kmlLaye13 = new this.google.maps.KmlLayer({
            map,
            url: 'https://privfile.com/download.php?fid=60dc70df05476-ODUyNw=='
        })
        const kmlLaye14 = new this.google.maps.KmlLayer({
            map,
            url: 'https://privfile.com/download.php?fid=60dc70df05476-ODUyOA=='
        })

        kmlLayer.setMap(this.$refs.gmap)
        kmlLayer1.setMap(this.$refs.gmap)
        kmlLaye2.setMap(this.$refs.gmap)
        kmlLaye3.setMap(this.$refs.gmap)
        kmlLaye4.setMap(this.$refs.gmap)
        kmlLaye5.setMap(this.$refs.gmap)
        kmlLaye6.setMap(this.$refs.gmap)
        kmlLaye7.setMap(this.$refs.gmap)
        kmlLaye8.setMap(this.$refs.gmap)
        kmlLaye9.setMap(this.$refs.gmap)
        kmlLaye10.setMap(this.$refs.gmap)
        kmlLaye11.setMap(this.$refs.gmap)
        kmlLaye12.setMap(this.$refs.gmap)
        kmlLaye13.setMap(this.$refs.gmap)
        kmlLaye14.setMap(this.$refs.gmap)
    })
  },
};
</script>