<template>
  <div id="app">
    <link
      rel="stylesheet"
      href="https://use.fontawesome.com/releases/v5.2.0/css/all.css"
      integrity="sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ"
      crossorigin="anonymous"
    />
    <div v-if="!isMobile()">
      <Navbar />
    </div>
    <div v-else>
      <HeaderMobile />
    </div>
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Navbar from "../src/components/Navbar.vue";
import Footer from "../src/components/Footer.vue";
import HeaderMobile from "../src/components/PersonalBlog/MobileView/HeaderMobile.vue";

export default {
  name: "home",
  components: {
    Navbar,
    HeaderMobile,
    Footer,
  },

  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss">
#app {
  flex: auto;
  max-width: 100% !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body,
html {
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

* {
  font-family: "Raleway", sans-serif;
  box-sizing: border-box;
  color: #303030;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
}

.h2-tag {
  font-size: 18px;
  letter-spacing: 1px;
}

p {
  font-size: 16px;
  line-height: 155.52%;
}

span {
  color: #6ca714;
}

.p-font-weight {
  // border: 1px solid red;
}

.main-body {
  width: 100%;
}

.add-padding {
  padding: 2rem 0;
}
.add-padding-smaller {
  padding: 1rem 0;
}
.container-small {
  width: 1000px;
  margin: auto;
  min-height: 100%;
}

.container {
  width: 1200px;
  margin: auto;
  box-sizing: border-box;
  min-height: 100%;
}

.container-tbca {
  width: 1000px;
  margin: auto;
}

.body-bottom_space-added {
  margin-bottom: 5rem;
}

/** Team profile */
.social-medias__team-profile {
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin: auto;
  margin-top: 15px;

  i {
    cursor: pointer;
    font-size: 24px;
    color: #6ca714;
  }

  i:hover {
    color: #5c9607;
  }
}

.team-member__profile {
  a {
    text-decoration: none;
    color: #6ca714;

    &:hover {
      text-decoration: underline;
    }
  }

  main {
    display: flex;
    flex-direction: row;
    flex: auto;
  }

  aside {
    flex: 1;
  }

  article {
    margin-left: 2rem;
    flex: 3;

    p {
      color: #303030;
    }
  }

  ul {
    width: 90%;
    margin: auto;

    li {
      font-size: 14px;
      padding-bottom: 5px;
      line-height: 22px;
      color: #303030;
    }
  }

  img {
    width: 100%;
  }
}
</style>
