<template>
  <nav>
    <div class="logo__cover">
      <p><a href="/"> Ecological Peace</a></p>
      <label for="drop" class="toggle">
        <i class="fas fa-bars"></i>
      </label>
    </div>
    <input type="checkbox" id="drop" />
    <ul class="menu">
      <li>
        <label for="drop-1" id="label__title" class="toggle">About Us +</label>
        <a href="#">About Us</a>
        <input type="checkbox" id="drop-1" />
        <ul>
          <li><a href="/project">The Project</a></li>
          <li><a href="/institutions">The Institutions</a></li>
          <li><a href="/about">The Team</a></li>
        </ul>
      </li>

      <li>
        <label for="drop-2" id="label__title" class="toggle">Resources +</label>
        <a href="#">Resources</a>
        <input type="checkbox" id="drop-2" />
        <ul>
          <li><a href="/writings">Publications</a></li>
          <li><a href="/media">Media</a></li>
        </ul>
      </li>
      <li class="nav-styling__contact"><a href="/contact">Contact Us</a></li>
      <li class="contact-us">
        <ModalLogin />
      </li>
    </ul>
  </nav>
</template>

<script>
import ModalLogin from "@/components/PersonalBlog/ModalLogin";

export default {
  components: {
    ModalLogin,
  },
};
</script>

<style lang="scss" scoped>
nav {
  -webkit-box-shadow: 0px 2px 15px -12px rgb(102, 102, 102);
  -moz-box-shadow: 0px 2px 15px -12px rgb(85, 85, 85);
  box-shadow: 1px 1px 5px rgb(32, 32, 32);
  width: 100%;

  .nav-styling__contact {
    * {
      padding: 1rem 0;
      width: 87%;
      margin: auto;
    }
  }

  .logo__cover {
    padding: 0.45rem 1.2rem 0;
    display: flex;
    justify-content: space-between;

    p {
      a {
        color: #6ca714;
        font-size: 20px;
      }
    }
  }

  i {
    color: #6ca714;
    font-size: 2rem;
  }

  #label__title {
    width: 87%;
    margin: auto;
  }
}

.toggle,
[id^="drop"] {
  display: none;
}

nav:after {
  content: "";
  display: table;
  clear: both;
}

nav ul {
  float: right;
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
}

nav ul li {
  display: inline-block;
  float: left;
  border: 1px solid rgba(221, 221, 221, 0.158);
  background-color: #303030;
}

nav a {
  display: block;
  color: #fff;
  font-size: 17px;
  text-decoration: none;
}

nav ul ul {
  display: none;
  position: absolute;
  top: 60px;
}

nav ul li:hover > ul {
  display: inherit;
}

nav ul ul li {
  width: 170px;
  float: none;
  display: list-item;
  position: relative;
}

.toggle + a,
.menu {
  display: none;
}

.toggle {
  display: block;
  color: #6ca714;
  font-size: 1.24rem;
  padding: 0.8rem 0;
}

[id^="drop"]:checked + ul {
  display: block;
}

nav ul li {
  display: block;
  width: 100%;
}

nav ul ul .toggle,
nav ul ul a {
  padding: 0 40px;
}

nav ul ul a,
nav ul ul ul a {
  padding: 14px 20px;
  color: #fff;
  font-size: 17px;
}

nav ul li ul li .toggle,
nav ul ul a {
  background-color: #212121;
}

/* Hide Dropdowns by Default */
nav ul ul {
  float: none;
  position: static;
  color: #ffffff;
}

/* Hide menus on hover */
nav ul ul li:hover > ul,
nav ul li:hover > ul {
  display: none;
}

/* Fisrt Tier Dropdown */
nav ul ul li {
  display: block;
  width: 100%;
}

nav ul ul ul li {
  position: static;
  border: 1px solid red;
}
</style>
