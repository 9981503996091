<template>
  <body class="add-padding team-member__profile">
    <main class="container">
      <aside>
        <br />
        <img src="../../assets/dorine.png" alt="" />
        <div class="social-medias__team-profile">
          <a href="https://www.facebook.com/1earth4peace">
            <i class="fab fa-facebook"></i
          ></a>
          <i class="fab fa-instagram"></i>
          <i class="fab fa-linkedin"></i>
          <i class="fab fa-twitter"></i>
        </div>
      </aside>

      <article>
        <TeamMemberProfile name="Dorine Intwarinkase Mutaganzwa" />
        <p>
          <strong>Dorine Intwarinkase Mutaganzwa</strong> is a Rwandan
          researcher in conservation and social sciences, that recently
          completed a master’s duo program in Biodiversity Conservation and
          Natural Resources Management at University of Rwanda and
          Bio-geosciences at University of Koblenz-Landaü.
        </p>
        <p>
          Her research interests include biodiversity conservation for community
          livelihoods, climate change and its mitigations in the tropics, as
          well as environmental justice. In fact, her master’s research project
          focused on understanding community perceptions on the impacts of a
          landscape approach to forest restoration on their socioeconomic
          wellbeing.
        </p>
        <p>
          She has experience in community conservation research, as she has
          worked on various projects on that topic as research assistant.
          Moreover, she has volunteered with World Wildlife Fund, Madagascar
          country office, where she worked on the protection of mangroves and
          reduction of poverty in the Ambaro Bay, Northern Madagascar, and
          Nature Rwanda where she worked on a project that focused on
          environmental education and engaging young people in conservation
          programs.
        </p>
        <p>
          She imagines a community where humans live in harmony with nature
          without compromising the well-being of the future generation. Thus,
          she wants to use her conservation and leadership skills to protect the
          biodiversity at the same time as she improves community well-being.
        </p>
        <a href="">
          <DownloadCv label="Download CV" />
        </a>
      </article>
    </main>
  </body>
</template>

<script>
import DownloadCv from "@/components/Models/DownloadCv.vue";
import TeamMemberProfile from "@/components/Models/TeamMemberProfile.vue";

export default {
  components: {
    DownloadCv,
    TeamMemberProfile,
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 600px) {
  body {
    width: 100%;
    padding: 10px;
    main {
      width: auto;
      flex-direction: column !important;
    }
    p {
      width: 100% !important;
    }
  }
}
</style>
