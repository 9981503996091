<template>
  <body class="add-padding team-member__profile">
    <main class="container">
      <aside>
        <br />
        <img class="logo" src="../../assets/protais.png" alt="" />
        <div class="social-medias__team-profile">
          <a href="https://www.facebook.com/profile.php?id=100007245843942">
            <i class="fab fa-facebook"></i
          ></a>
          <i class="fab fa-instagram"></i>
          <a href="https://www.linkedin.com/in/dan-brockington-9aa07513/">
            <i class="fab fa-linkedin"></i
          ></a>
          <a href="https://twitter.com/MusaSKanneh6">
            <i class="fab fa-twitter"></i
          ></a>
        </div>
      </aside>

      <article>
        <TeamMemberProfile name="Protais Seshaba" />
        <p>
          <strong>Protais Seshaba</strong>
          is an environmental scientist with a Bachelor degree in Botany and
          Conservation as well as a Masters degree in Geo-Information Science
          for Environment and Sustainable Development, both obtained from the
          University of Rwanda. His undergraduate research project was about
          assessing the impacts of exotic plant species on natural vegetation
          dynamics in the Ruhande Arboretum, while his Master’s thesis project
          was an analysis of climate variability in Rwanda, as indicated by
          temperature records since the 1930s.
        </p>

        <p>
          Protais served as research assistant to many projects carried out in
          Rwanda in the sectors of ecology, biodiversity conservation,
          socio-economic analysis, GIS and tourism. Specific project include but
          are not limited to:
        </p>
        <ul>
          <li>
            Research assistant with KU Leuven University (Belgium), the Flemish
            Institute for Technological Research (VITO, Belgium) and the
            University of Rwanda: Land cover mapping and carbon stock estimation
            in Rwanda;
          </li>
          <li>
            Member of the joint Utrecht University & University of Rwanda
            International RurUrbanAfrica research for understanding local
            livelihood practices and socio-mobility;
          </li>
          <li>
            Member of AidEnvironment for rural socio-economic projects funded by
            Trocaire Rwanda in southern districts of Rwanda;
          </li>
        </ul>

        <p>
          Protais was also an Operations Assistant at Wilderness Safaris Rwanda,
          a high end tourism operator in Rwanda with a mission to conserve
          pristine landscapes and empower communities around national parks. His
          background studies, work experience and enthusiastic research
          contributions have instilled in him a strong understanding of
          human-environment interactions for sustainable development.
        </p>

        <p>
          In his free moments, Protais likes exploring tourist sites, singing
          and reading music in his choir, as well as playing guitar.
        </p>

        <a href="">
          <DownloadCv label="Download CV" />
        </a>
      </article>
    </main>
  </body>
</template>

<script>
import DownloadCv from "@/components/Models/DownloadCv.vue";
import TeamMemberProfile from "@/components/Models/TeamMemberProfile.vue";
export default {
  components: {
    DownloadCv,
    TeamMemberProfile,
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 600px) {
  body {
    width: 100%;
    padding: 10px;
    main {
      width: auto;
      flex-direction: column !important;
    }
    p {
      width: 100% !important;
    }
  }
}
</style>
