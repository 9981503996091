import { render, staticRenderFns } from "./TeamMemberProfile.vue?vue&type=template&id=d4b162e0&scoped=true&"
import script from "./TeamMemberProfile.vue?vue&type=script&lang=js&"
export * from "./TeamMemberProfile.vue?vue&type=script&lang=js&"
import style0 from "./TeamMemberProfile.vue?vue&type=style&index=0&id=d4b162e0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d4b162e0",
  null
  
)

export default component.exports