<template>
  <header class="header">
    <nav class="container">
      <a href="/"><h2 class="ecological-peace">Ecological Peace</h2></a>
      <ul class="nav-side-items">
        <li>
          <a class="drop-down-liks">ABOUT US</a>
          <ul>
            <li class="link">
              <a href="/project">THE PROJECT</a>
            </li>
            <li class="link">
              <a href="/about">THE TEAM</a>
            </li>
            <li class="link">
              <a href="/institutions">THE INSTITUTIONS</a>
            </li>
          </ul>
        </li>
        <li class="drop-down-liks">
          RESOURCES
          <ul>
            <li class="link">
              <a href="/writings">PUBLICATIONS </a>
            </li>

            <li class="link">
              <a href="/media">MEDIA</a>
            </li>
          </ul>
        </li>
        <li>
          <a href="/contact">CONTACT US</a>
        </li>
      </ul>
      <div class="contact-us">
        <ModalLogin />
      </div>
    </nav>
  </header>
</template>

<script>
import ModalLogin from "@/components/PersonalBlog/ModalLogin";

export default {
  components: {
    ModalLogin,
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 650px) {
  * {
    background-color: black;
  }

  header nav ul li {
    float: none;
    display: block;
    text-align: left;
  }
}

header {
  z-index: 1;
  background-color: #181717;
  -webkit-box-shadow: 0px 2px 15px -12px rgba(0, 0, 0, 0.618);
  -moz-box-shadow: 0px 2px 15px -12px rgba(0, 0, 0, 0.57);
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.57);
  width: 100%;
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0 !important;
}

.ecological-peace {
  margin-top: 1.5rem;
  float: left;
  width: 100%;
  font-weight: 400;
  font-size: 1.5rem;
  color: #fbfbfb;

  &:hover {
    color: #6ca714;
    transition: 1s ease;
  }
}

nav {
  --menu-height: 28px;
  opacity: 0.9;
  justify-content: space-between;
  display: flex;

  ul {
    list-style: none;
    margin: 1rem 0;

    li,
    li a {
      font-size: 12px;
      z-index: 1;
      color: #fbfbfb;
      cursor: pointer;
      transition: 200ms;
      text-decoration: none;
      font-weight: 500;

      &:hover {
        opacity: 1;
      }

      a {
        height: 100%;
        width: 100%;
      }
    }

    .drop-down-liks {
      &::before {
        content: "";
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #6ca714;
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    li {
      padding-right: 36px;
    }

    .link {
      &::before {
        padding-right: 0;
        display: none;
      }
    }
  }

  > ul {
    display: flex;
    height: var(--menu-height);
    align-items: center;

    li {
      position: relative;
      margin: auto 8px 0 auto;

      ul {
        visibility: hidden;
        opacity: 1;
        z-index: 1;
        padding: 0;
        min-width: 160px;
        background-color: #101010;
        position: absolute;
        top: calc(var(--menu-height) + 5px);
        left: 50%;
        transform: translateX(-50%);
        transition: 200ms;
        transition-delay: 200ms;

        li {
          margin: 0;
          padding: 10px 16px;
          align-items: center;

          &::before {
            width: 0;
            height: 0;
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
            border-left: 5px solid #6ca714;
          }

          ul {
            top: -2%;
            left: 100%;
            transform: translate(0);
          }

          &:hover {
            background-color: #6ca714;
          }
        }
      }

      &:hover {
        > ul {
          visibility: visible;
          transition-delay: 0ms;
        }
      }
    }
  }
}
</style>
