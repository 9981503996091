<template>
  <body>
    <h2>Advanced search</h2>
    <main class="container-small">
      <div class="contact-cover">
        <form action="/">
          <div class="first-sec">
            <label for="cars">Document type: </label>
            <select name="cars" class="docs">
              <option value="volvo">-Any-</option>
              <option value="saab">Article</option>
              <option value="saab">Report</option>
              <option value="saab">Media</option>
              <option value="saab">Documents</option>
              <option value="opel">Monograph</option>
              <option value="audi">Periodical</option>
            </select>

            &nbsp;&nbsp;

            <label for="cars"> Select by region/country: </label>
            <select name="cars" class="docs">
              <option value="volvo">Afghanistan</option>
              <option value="saab">Åland Islands</option>
              <option value="opel">Albania</option>
              <option value="audi">Algeria</option>
            </select>
            <br />
            <input
              type="text"
              placeholder="Name of protected area"
              label="Title"
            />
            <input type="text" placeholder="Document title" label="Title" />
          </div>
          <div class="second-sec">
            <label for="cars">Publication year: </label>
            <select name="cars" id="cars">
              <option value="volvo">Is less than</option>
              <option value="saab">Is greater than or equal to</option>
              <option value="opel">Is not equal to</option>
              <option value="audi">Is greater than</option>
              <option value="audi">Is between</option>
            </select>
            &nbsp;&nbsp;
            <input
              type="text"
              placeholder="Enter year. eg: 1979"
              label="publication year"
            />
            <br /><br />
            <label for="cars"> Sort by: </label>
            <select name="cars" id="cars">
              <option value="volvo">Publication year</option>
              <option value="saab">Publication title</option>
            </select>
          </div>
        </form>
        <br />
        <input class="submit" type="submit" value="Apply search" />

        <div class="total-entries">
          <p>
            This list contains
            <strong>{{ totalDocumentEntries }}</strong> entry(ies).
          </p>
        </div>
      </div>
    </main>
  </body>
</template>

<script>
export default {
  components: {},

  data() {
    return {
      totalDocumentEntries: 3000,
    };
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
@media (max-width: 600px) {
  body {
    width: 100%;

    main {
      width: auto;
    }
    main .contact-cover {
      width: 100%;

      form {
        display: grid;
        grid-template-columns: 1fr;

        .first-sec {
          width: 100%;
          margin-bottom: 50px;
        }

        .second-sec {
          width: 100%;
        }
      }
    }
  }
}

body {
  width: 100%;
  .total-entries {
    p {
      margin-top: 1rem;
      background-color: #6ca71469;
    }
  }

  h2 {
    color: #606060;
  }

  .contact-cover {
    width: 100%;

    form {
      display: grid;
      grid-template-columns: 1fr 1fr;

      .first-sec {
        width: 100%;
      }

      .second-sec {
        width: 100%;
      }

      label {
        font-size: 13px;
      }

      input {
        outline: none;
        font-family: "Raleway", sans-serif;
        width: 92%;
        margin-top: 0.8rem;
        height: 35px;
        border: 1px solid rgb(106, 106, 106);
        border-radius: 3px;
        padding: 0 20px;
        color: rgb(106, 106, 106);
        font-size: 13px;
      }
    }
  }

  .submit {
    border: 1px solid #6ca714;
    background-color: #6ca714;
    color: #ffffff;
    padding: 0.6em 2.5rem;
    font-size: 13px;
    border-radius: 4px;
    letter-spacing: 0.5px;
    outline: none;
    cursor: pointer;

    &:hover {
      background-color: #446a0b;
      transition: 1s ease;
      color: #ffffff;
    }
  }

  h2 {
    margin: auto;
    font-size: 18px;
    padding: 1rem 0;
  }

  button:focus {
    outline: none;
  }
}
</style>
