<template>
  <body>
    <Captions caption="Other Publications"></Captions>
    <main class="container">
      <section class="publication-cover">
        <aside class="aside">
          <div class="creds">
            <h3>Academic work</h3>
            <hr />
            <p>
              PhD Dissertation:
              <a href="http://hdl.handle.net/2429/67561">
                Protecting Places for Nature, People, and Peace: A Critical
                Socio-Legal Review of Transboundary Conservation Areas
              </a>
              (Supervisor: Professor Philippe Le Billon)
            </p>
            <p>
              LLM Thesis:
              <a href="http://digitalcommons.pace.edu/lawdissertations/7/">
                Peace Parks for Mountain Forests: The Law and Policy of
                Transforming Conflict to Stewardship
              </a>
              (Supervisor: Professor Nicholas A. Robinson)
            </p>
          </div>
        </aside>

        <aside class="aside">
          <div class="creds">
            <h3>Refereed Journal Articles</h3>
            <hr />
          </div>
          <div class="publications">
            <div>
              <p>2020</p>
            </div>
            <article>
              <p>
                <a href="">
                  Protecting protected areas in bello: learning from
                  institutional design and conflict-resilience in the Greater
                  Virunga and Kidepo Landscapes.</a
                >
                Goettingen Journal of International Law 10(1): 67-110.
              </p>
            </article>
          </div>
          <div class="publications">
            <div>
              <p>2018</p>
            </div>
            <article>
              <p>
                <a
                  href="https://www.iucnael.org/en/e-journal/previous-issues/86-journal/issue/636-issue-2018"
                >
                  Nomoscaping Peace in Times of Conflict: A Case Study of the
                  Greater Virunga Transboundary Collaboration (GVTC) and
                  Conflict.</a
                >
                IUCN AEL EJournal Special Issue 9: 79-103.
              </p>
            </article>
          </div>
          <div class="publications">
            <div>
              <p>2015</p>
            </div>
            <article>
              <p>
                <a
                  href="https://www.academia.edu/33913311/Water_for_Peace_and_Resilience_in_the_Central_Albertine_Rift"
                >
                  Water for Peace and Resilience: Transboundary Integrated Water
                  Management for the Central Albertine Rift,</a
                >
                South African Journal of Environmental Law and Policy 20: 65-87.
              </p>
            </article>
          </div>
          <div class="publications">
            <div>
              <p>2012</p>
            </div>
            <article>
              <p>
                <a
                  href="https://www.researchgate.net/publication/235671679_Whanganui_River_Agreement_-_Indigenous_Rights_and_Rights_of_Nature"
                >
                  Whanganui River Agreement: Indigenous Rights and Rights of
                  Nature.</a
                >
                Environmental Policy and Law 42(6): 371-376 (Dec.), reprinted in
                Just Between the Law and Us: Persons and Property, Vol. 1 (Neil
                Sargent & Login Atkinson eds., 2016).
              </p>
            </article>
          </div>
        </aside>

        <aside class="aside">
          <div class="creds">
            <h3>Books</h3>
            <hr />
          </div>
          <div class="publications">
            <div>
              <p>2012</p>
            </div>
            <article>
              <p>
                <a
                  href="https://www.researchgate.net/publication/235671679_Whanganui_River_Agreement_-_Indigenous_Rights_and_Rights_of_Nature"
                >
                  Whanganui River Agreement: Indigenous Rights and Rights of
                  Nature.</a
                >
                Environmental Policy and Law 42(6): 371-376 (Dec.), reprinted in
                Just Between the Law and Us: Persons and Property, Vol. 1 (Neil
                Sargent & Login Atkinson eds., 2016).
              </p>
            </article>
          </div>
        </aside>
      </section>
    </main>
  </body>
</template>

<script>
import Captions from "@/components/Captions";
export default {
  components: {
    Captions,
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 600px) {
  body {
    padding: 10px;

    width: 100%;

    main {
      width: auto;
    }

    main .publication-cover {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr;
    }
  }
}

body {
  main {
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.06);
  }

  aside {
    text-align: left;
    background-color: #fff;
    padding: 0 15px;
  }

  .publication-cover {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
  }

  h3 {
    text-align: left;
    color: #303030;
  }

  hr {
    border: 1px solid rgba(128, 128, 128, 0.158);
  }

  a {
    text-decoration: none;
    color: #6ca714;
    &:hover {
      text-decoration: underline;
    }
  }

  .creds {
    width: 95%;
    margin: auto;

    p {
      font-weight: 400;
    }
  }

  .publications {
    display: flex;
    flex-direction: row;
    flex: auto;
    justify-content: space-between;

    p {
      padding-right: 15px;
    }

    article {
      width: 90%;
    }
  }
}
</style>
