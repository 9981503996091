<template>
  <div>
    <button
      :style="style"
      :class="{
        fullWidth: isFullWidth,
        halfWidth: isFullWidth,
        primaryColor: isPrimaryColor,
        secondaryColor: isSecondaryColor,
      }"
      class="main-button"
    >
      {{ label }}
    </button>
  </div>
</template>

<script>
export default {
  name: "main-button",

  data() {
    return {
      style: "width:" + this.width,
    };
  },

  props: {
    label: {
      type: String,
      required: true,
    },

    isFullWidth: {
      type: Boolean,
      required: true,
    },

    width: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 900px) {
  .main-button {
    width: 50% !important;
    font-size: 14px !important;
    height: 40px !important;
  }
}

.secondaryColor {
  border: 1px solid re;
}

.fullWidth {
  width: 100%;
}

.halfWidth {
  width: 60%;
}

.main-button {
  font-size: 15px;
  background-color: #649c0f;
  color: #ffffff;
  height: 40px;
  padding: 0 0.8rem;
  width: auto;
  font-size: 14px;
  border-radius: 4px;
  border-style: none;
  outline: none;
  order: 2;
  cursor: pointer;
  text-align: center;
}

.main-button:hover {
  background: #49720a;
  transition: 1s ease;
  color: #ffffff;
}

button:focus {
  outline: none;
}
</style>
