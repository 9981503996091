<template>
  <div class="input-styled">
    <br />
    <input
      @click="showOutline = true"
      @mouseleave="showOutline = false"
      :style="getClass()"
      v-if="!isTextArea"
      @keyup="$emit('sendInput', model)"
      :type="type"
      v-model="model"
      :placeholder="placeholder"
    />

    <textarea
      @click="showOutline = true"
      @mouseleave="showOutline = false"
      :style="getClass()"
      @keyup="$emit('sendInput', model)"
      rows="5"
      v-else
      v-model="model"
    >
    </textarea>
  </div>
</template>

<script>
export default {
  name: "",
  data: function() {
    return {
      model: "",
      showOutline: false,
    };
  },
  methods: {
    getClass() {
      return this.showOutline ? "border: 1px solid #8bbd42 !important;" : "";
    },
  },
  props: {
    placeholder: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    isTextArea: {
      type: Boolean,
      required: false,
    },
    value: {
      type: String,
      required: false,
    },
  },
  created() {
    if (this.value) {
      this.model = this.value;
    }
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 600px) {
  .input-styled {
    width: 90% !important;
  }
}
.input-styled {
  margin: auto;
  width: 70%;

  input {
    font-family: "Raleway", sans-serif;
    width: 100%;
    height: 40px;
    border: 1px solid #649c0f;
    border-radius: 5px;
    padding: 0 20px;
    color: rgb(106, 106, 106);
    font-size: 13px;
  }

  input:focus {
    outline: none;
  }

  textarea {
    font-family: "Raleway", sans-serif;
    width: 100%;
    border: 1px solid #649c0f;
    border-radius: 5px;
    color: rgb(106, 106, 106);
    font-size: 13px;
  }

  textarea:focus {
    outline: none;
  }
}
</style>
