<template>
  <body class="">
    <div class="description">
      <p class="container-small">
        If you cannot your TBCA here in the database and/or would like to add
        information to the database, please complete the form below:
      </p>
    </div>
    <main class="container-small">
      <h2>Personal Information</h2>
      <div class="contact-cover">
        <form action="/">
          <div>
            <div class="first-sec">
              <input type="text" placeholder="Your full name" label="Title" />
              <input type="text" placeholder="Your Affiliation" label="Title" />
              <input type="text" placeholder="Your Affiliation" label="Title" />
            </div>
          </div>
        </form>
      </div>
      <br />
      <h2>Upload TBCA information</h2>
      <div class="contact-cover">
        <form action="/">
          <div>
            <div class="first-sec">
              <input type="text" placeholder="Name" label="Title" />
              <input type="text" placeholder="Designation" label="Title" />
              <div>
                <label for="cars"> Select by region/country: </label>
                <select name="cars" class="docs">
                  <option value="volvo">Afghanistan</option>
                  <option value="saab">Åland Islands</option>
                  <option value="opel">Albania</option>
                  <option value="audi">Algeria</option>
                </select>
              </div>
            </div>
            <br />
            <div class="first-sec">
              <div>
                <label class = "label" for="cars"> Status Year: </label>
                <select name="cars" class="docs">
                  <option value="volvo">Afghanistan</option>
                  <option value="saab">Åland Islands</option>
                  <option value="opel">Albania</option>
                  <option value="audi">Algeria</option>
                </select>
              </div>
              <div>
                <label for="cars"> Location: </label>
                <select name="cars" class="docs">
                  <option value="volvo">Afghanistan</option>
                  <option value="saab">Åland Islands</option>
                  <option value="opel">Albania</option>
                  <option value="audi">Algeria</option>
                </select>
              </div>
              <div>
                <label for="cars"> Governance type: </label>
                <select name="cars" class="docs">
                  <option value="volvo">Afghanistan</option>
                  <option value="saab">Åland Islands</option>
                  <option value="opel">Albania</option>
                  <option value="audi">Algeria</option>
                </select>
              </div>
            </div>
            <br />

            <div class="first-sec">
              <div>
                <label for="cars"> Management authority: </label>
                <select name="cars" class="docs">
                  <option value="volvo">Afghanistan</option>
                  <option value="saab">Åland Islands</option>
                  <option value="opel">Albania</option>
                  <option value="audi">Algeria</option>
                </select>
              </div>
              <input
                type="text"
                placeholder="Contact Information"
                label="Title"
              />
              <div>
                <label for="cars">International Criteria : </label>
                <select name="cars" class="docs">
                  <option value="volvo">Afghanistan</option>
                  <option value="saab">Åland Islands</option>
                  <option value="opel">Albania</option>
                  <option value="audi">Algeria</option>
                </select>
              </div>
            </div>
            <br />
            <div class="first-sec">
              <input type="text" placeholder="GIS data points " label="Title" />
              <input type="text" placeholder="Reported area" label="Title" />
            </div>
          </div>
        </form>
      </div>
      <br />
      <h2>Upload TBCA documentation</h2>
      <div class="submit-documents-cover">
        <div class="upload-file">
          <h3>TBCA agreements</h3>
          <form action="/action_page.php">
            <input type="file" class="myFile" name="filename" />
            &nbsp;
            <input class="submit-file" type="submit" />
          </form>
        </div>
        <div class="upload-file">
          <h3>Management Plan</h3>
          <form action="/action_page.php">
            <input type="file" class="myFile" name="filename" />
            &nbsp;
            <input class="submit-file" type="submit" />
          </form>
        </div>
        <div class="upload-file">
          <h3>Reports</h3>
          <form action="/action_page.php">
            <input type="file" class="myFile" name="filename" />
            &nbsp;
            <input class="submit-file" type="submit" />
          </form>
        </div>
        <div class="upload-file">
          <br />
          <h3>Media files (videos, photos)</h3>
          <form action="/action_page.php">
            <input type="file" class="myFile" name="filename" />
            &nbsp;
            <input class="submit-file" type="submit" />
          </form>
        </div>
        <div class="upload-file">
          <br />
          <h3>Website(s)</h3>
          <form action="/action_page.php">
            <input type="file" class="myFile" name="filename" />
            &nbsp;
            <input class="submit-file" type="submit" />
          </form>
        </div>
        <div class="upload-file">
          <br />
          <h3>Other Resources</h3>
          <form action="/action_page.php">
            <input type="file" class="myFile" name="filename" />
            &nbsp;
            <input class="submit-file" type="submit" />
          </form>
        </div>
      </div>
      <br /><br />
      <div class="terms-and-conditions">
        <p>
          <span>*</span> I consent to have this information published in the
          TBCA database
        </p>
        <div><input type="checkbox" id="" name="" value="" /></div>
      </div>
      <br />
      <input class="submit" type="submit" value="Apply submission" />
    </main>
    <br /><br />
  </body>
</template>

<script>
export default {
  components: {},

  data() {
    return {
      totalDocumentEntries: 3000,
    };
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
@media (max-width: 600px) {
  * {
    width: 100%;
  }

  body {
    width: auto;

    main .contact-cover {
      width: 100%;

      form {
        .first-sec {
          display: grid;
          grid-template-columns: 1fr;
        }

        input {
          margin-bottom: 20px;
        }

      }
    }

    main .submit-documents-cover {
      display: grid;
      grid-template-columns: 1fr;
    }

  }
}
body {
  width: 100%;
  background-color: #fbfbfb;
  .total-entries {
    p {
      background-color: #6ca71469;
    }
  }

  .description {
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.712)),
      url("../../assets/bg.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    margin: auto;
    padding: 2.5rem 0;
    opacity: 1;
    background-size: cover;
    z-index: 1;

    p {
      color: #ffffff;
      font-size: 15px;
    }
  }

  .terms-and-conditions {
    display: flex;

    p {
      font-size: 12px;
      margin: 0;
    }

    span {
      color: red;
      font-size: 20px;
    }
  }

  .submit-documents-cover {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    .margin-top {
      margin-top: 1em;
    }

    .upload-file {
      width: 95%;

      h3 {
        color: #606060;
        font-size: 14px;
      }

      .submit-file {
        border: 1px solid #6ca714;
        background-color: #6ca714;
        color: #ffffff;
        padding: 0.28em 0.3em;
        font-size: 13px;
        letter-spacing: 0.5px;
        outline: none;
        cursor: pointer;
        background-color: #649c0f;
        color: #ffffff;
        border-radius: 4px;
        border-style: none;
        order: 2;
      }

      .myFile {
        background-color: #ffffff;
        outline: none;
        width: 80%;
        cursor: pointer;
        border: 1px solid #649c0fbd;
        border-radius: 4px;
        justify-content: flex-start;
      }
    }
  }

  h2 {
    color: #606060;
  }

  .contact-cover {
    width: 95%;

    form {
      .first-sec {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
      }

      label {
        font-size: 13px;
      }

      input {
        outline: none;
        font-family: "Raleway", sans-serif;
        width: 95%;
        height: 35px;
        border: 1px solid rgb(106, 106, 106);
        border-radius: 3px;
        padding: 0 20px;
        color: rgb(106, 106, 106);
        font-size: 13px;
      }
    }
  }

  .submit {
    border: 1px solid #6ca714;
    background-color: #6ca714;
    color: #ffffff;
    padding: 0.6em 2.5rem;
    font-size: 13px;
    border-radius: 4px;
    letter-spacing: 0.5px;
    outline: none;
    cursor: pointer;

    &:hover {
      background-color: #446a0b;
      transition: 1s ease;
      color: #ffffff;
    }
  }

  h2 {
    margin: auto;
    font-size: 18px;
    padding: 1rem 0;
  }

  button:focus {
    outline: none;
  }
}
</style>
