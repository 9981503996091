<template>
  <body>
    <main>
      <div>
        <ProfileCard label="Alte Spreemündung" totalTBCA="10,00" />
      </div>
      <ProfileCard label="Große Göhlenze Und Fichtengrund" totalTBCA="10,00" />
      <ProfileCard label="Albania" totalTBCA="10,00" />
    </main>
    <br /><br /><br /><br />
  </body>
</template>

<script>
import ProfileCard from "@/components/Models/TBCA/ProfileCard.vue";

export default {
  components: { ProfileCard },

  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
body {
  main {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 15px;
  }
}
</style>
