<template>
  <body class="add-padding team-member__profile">
    <main class="container">
      <aside>
        <img class="logo" src="../../assets/rosaleen.jpg" alt="" />
        <div class="social-medias__team-profile">
          <a href="https://www.facebook.com/profile.php?id=100007245843942">
            <i class="fab fa-facebook"></i
          ></a>
          <i class="fab fa-instagram"></i>
          <a href="https://www.linkedin.com/in/dan-brockington-9aa07513/">
            <i class="fab fa-linkedin"></i
          ></a>
          <a href="https://twitter.com/MusaSKanneh6">
            <i class="fab fa-twitter"></i
          ></a>
        </div>
      </aside>

      <article>
        <TeamMemberProfile name="Rosaleem Duffy" />
        <p>
          <strong>Rosaleen Duffy</strong> is Professor of International Politics
          at the University of Sheffield, UK. She is a political ecologist,
          focusing on the international politics of conservation. Her research
          foci include the neoliberalisation of nature, global environmental
          governance, transfrontier conservation, the illegal wildlife trade and
          ecotourism.
        </p>
        <p>
          From 2016-2020 she was PI on the BIOSEC Project, funded by the
          European Research Council; it examined the intersections between
          political ecology, conservation and security via an analysis of the
          illegal wildlife trade. She is author of Nature Crime (Yale 2010) and
          co-author of Nature Unbound (with Dan Brockington and James Igoe,
          Routledge, 2008). Rosaleen joined the University of Sheffield in 2016,
          and previously held posts at Lancaster University, Manchester
          University and SOAS University of London.
        </p>
        <a href="">
          <DownloadCv label="Download CV" />
        </a>
      </article>
    </main>
  </body>
</template>

<script>
import DownloadCv from "@/components/Models/DownloadCv.vue";
import TeamMemberProfile from "@/components/Models/TeamMemberProfile.vue";

export default {
  components: {
    DownloadCv,
    TeamMemberProfile,
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 600px) {
  body {
    width: 100%;
    padding: 10px;

    main {
      width: auto;
      flex-direction: column !important;
    }
    p {
      width: 100% !important;
    }
  }
}
</style>
