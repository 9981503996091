<template>
  <body>
    <br />
    <main class="container">
      <div class="sec-one-cover">
        <aside>
          <div>
            <h1>{{ tbcaName }}</h1>
            <div>
              <h2>{{ tbcaId }}</h2>
              <h3>ID</h3>
            </div>
            <div>
              <h2>{{ squareKilometer }}</h2>
              <h3>Reported Area</h3>
            </div>
            <div>
              <h2>{{ type }}</h2>
              <h3>Type</h3>
            </div>
            <div>
              <h2>{{ location }}</h2>
              <h3>Location</h3>
            </div>
          </div>
        </aside>
        <article>
          <div class="map-cover">
            <TBCAMap />
            <div>
              <h3>Map Disclaimer</h3>
              <p>
                The designations employed and the presentation of material on
                this map do not imply the expression of any opinion whatsoever
                on the part of the Secretariat of the United Nations concerning
                the legal status of any country, territory, city or area or of
                its authorities, or concerning the delimitation of its frontiers
                or boundaries.
              </p>
            </div>
          </div>
        </article>
      </div>
      <Attributes />
      <div class="list-of-protected-areas">
        <h2>Other Protected Areas</h2>
        <div class="items">
          <ProtectedAreas name="Sapo national park"></ProtectedAreas>
          <ProtectedAreas name="Sapo national park"></ProtectedAreas>
          <ProtectedAreas name="Sapo national park"></ProtectedAreas>
          <ProtectedAreas name="Sapo national park"></ProtectedAreas>
        </div>
        <br />
        <a href="#">
          <Button
            class="mobile-style-1"
            style="margin-left: 0;"
            :isFullWidth="false"
            label="View all"
            width="150px"
          ></Button>
        </a>
      </div>
    </main>
    <br /><br />
  </body>
</template>

<script>
import Button from "@/components/Button.vue";
import TBCAMap from "../../components/TBCADatabases/TBCAMap";
import Attributes from "../../components/TBCADatabases/Attributes";
import ProtectedAreas from "@/components/TBCADatabases/ProtectedAreas.vue";

export default {
  components: {
    Button,
    TBCAMap,
    Attributes,
    ProtectedAreas,
  },

  data() {
    return {
      pageOne: "This is page one",
      tbcaName: "Sapo National Park",
      tbcaId: "14541",
      squareKilometer: "471.22 km²",
      type: "Protected Area",
      location: "Liberia",
    };
  },

  methods: {
    showPage() {
      return this.pageOne;
    },

    getLandMass() {},
  },
};
</script>

<style lang="scss" scoped>
body {
  background: #f4f4f4;
  width: 100%;

  .list-of-protected-areas {
    .items {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      width: 100%;
    }
  }
  main {
    h1,
    h3 {
      color: #ffffff;
    }
    h2 {
      color: #649c0f;
    }
    h3 {
      font-size: 14px;
      letter-spacing: 0.5px;
    }
    .sec-one-cover {
      display: grid;
      grid-template-columns: 1fr 2fr;

      article {
        background-color: #101010;
        padding: 1.5rem;
      }

      aside {
        background-color: #242424;
        padding: 0.5rem 1.5rem;
      }
    }
  }
}
</style>
