<template>
  <body>
    <article class="container">
      <main>
        <h2><i class="fa fa-file-signature"></i> Connect with us</h2>
        <div class="contact-cover">
          <StyledInput
            type="text"
            class="input"
            placeholder="Enter your  first name"
            label="First name"
          />
          <StyledInput
            type="text"
            placeholder="Enter your last name"
            label="Last name"
          />

          <StyledInput
            type="text"
            placeholder="Enter your email address"
            label="Email address"
          />

          <StyledInput
            :isTextArea="true"
            type="text"
            placeholder=""
            label="Message"
          />
          <Button label="Submit Form" width="50%" />
        </div>
      </main>
    </article>
  </body>
</template>

<script>
import StyledInput from "@/components/StyledInput";
import Button from "@/components/Button.vue";

export default {
  components: {
    StyledInput,
    Button,
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 600px) {
  body {
    article {
      width: auto;
    }

    main {
      width: 90%;
      margin: auto;
    }
  }
}

body {
  background: #fbfbfb;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  height: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.712)),
    url("../../assets/land2.jpeg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  opacity: 1;
  background-size: cover;
  z-index: 1;
  padding: 6% 0;
}

main {
  width: 50%;
  background-color: #ffffff;
  border-radius: 0 1rem 1rem 0;
  opacity: 1;
  padding: 2rem 0 4rem 0;
}

img {
  width: 80%;
}

i {
  color: #6ca714;
}

h2 {
  text-align: center;
  margin: auto;
  font-size: 18px;
  padding-bottom: 1rem;
}

article {
  display: flex;
}

.contact-cover {
  width: 100%;
  background-color: green;
}
</style>
