<template>
  <body>
    <Captions caption="Other Publications"></Captions>
    <main class="container">
      <section class="publication-cover">
        <aside>
          <h2>Education</h2>
          <hr />
          <div>
            <h4>2011 - 2018</h4>
            <h3>
              University of British Columbia Institute for Resources,
              Environment & Sustainability
            </h3>
            <div class="inner">
              <p>
                Doctor of Philosophy in Resource Management & Environmental
                Studies
              </p>
            </div>
          </div>

          <div class="content-cover">
            <h4>2009 - 2010</h4>
            <h3>
              Pace University School of Law
            </h3>
            <div class="inner">
              <p>
                Master of Laws in Environmental Law
              </p>
            </div>
          </div>

          <div class="content-cover">
            <h4>2006 - 2009</h4>
            <h3>
              Pace University School of Law
            </h3>
            <div class="inner">
              <p>
                Juris Doctor in Environmental Law & International Law
              </p>
            </div>
          </div>

          <div class="content-cover">
            <h4>2000 - 2004</h4>
            <h3>
              University of California, Los Angeles
            </h3>
            <div class="inner">
              <p>
                Bachelor of Arts in International Economics, Minor in East Asian
                Languages & Cultures
              </p>
            </div>
          </div>
        </aside>
        <aside>
          <h2>Research Interest</h2>
          <hr />
          <div>
            <h3>Ecological Peacebuilding</h3>
            <div class="inner">
              <p>
                Addressing international, social and ecological conflicts
                through environmental cooperation.
              </p>
            </div>
          </div>

          <div class="content-cover">
            <h3>Transboundary Conservation Areas</h3>
            <div class="inner">
              <p>
                Protecting places for nature, people and peace across
                boundaries.
              </p>
            </div>
          </div>

          <div class="content-cover">
            <h3>Environmental Change and Migration</h3>
            <div class="inner">
              <p>
                Understanding the social and ecological implications of
                environmental change, migration and conflict.
              </p>
            </div>
          </div>

          <div class="content-cover">
            <h3>Indigenous and Community Conservation</h3>
            <div class="inner">
              <p>
                Governing ICCAs - territories of life through inclusive
                conservation for all our relations.
              </p>
            </div>
          </div>
        </aside>

        <aside>
          <h2>Writtings</h2>
          <hr />
          <div class="content-cover">
            <h3>
              Protecting Protected Areas in <em>Bello</em>: Learning From
              Institutional Design and Conflict Resilience in the Greater
              Virunga and Kidepo Landscapes
            </h3>
            <div class="inner">
              <a
                href="https://drive.google.com/drive/u/1/folders/1LEjE3Y5KOM18EbXjLDwiL9m2icLoxybB"
                target="_blank"
                >Read more</a
              >
            </div>
          </div>
          <div class="content-cover">
            <h3>
              Environmental Policy and Law
            </h3>
            <div class="inner">
              <a
                href="https://drive.google.com/file/d/1Vkgwebpb-FSe0wkT35wKDll6NOXpWOgM/view"
                target="_blank"
                >Read more</a
              >
            </div>
          </div>

          <div class="content-cover">
            <h3>
              Water for peace and Resiliance in the Central Albertine Rift
            </h3>
            <div class="inner">
              <a
                href="https://drive.google.com/drive/u/1/folders/1LEjE3Y5KOM18EbXjLDwiL9m2icLoxybB"
                target="_blank"
                >Read more</a
              >
            </div>
          </div>

          <div class="content-cover">
            <h3>
              NOMOSCAPING PEACE IN TIMES OF CONFLICT: A Case Study of the
              Greater Virunga Transboundary Collaboration (GVTC)
            </h3>
            <div class="inner">
              <a
                href="https://drive.google.com/drive/u/1/folders/1LEjE3Y5KOM18EbXjLDwiL9m2icLoxybB"
                target="_blank"
                >Read more</a
              >
            </div>
          </div>
        </aside>
      </section>
    </main>
  </body>
</template>

<script>
import Captions from "@/components/Captions";
export default {
  components: {
    Captions,
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 600px) {
  body {
    width: 100%;
    padding: 10px;

    main {
      width: auto;
      flex-direction: column !important;
    }

    p {
      width: 100% !important;
    }

    main .publication-cover {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr;
    }
  }
}

body {
  aside {
    width: 98%;
    background-color: #fff;
    padding: 0 10px;
    box-shadow: 0 0 5px 0.5px rgba(0, 0, 0, 0.123);
    span {
      color: #6ca714;
    }

    p {
      font-weight: 400;
      width: 90%;
    }

    h3 {
      color: #474747;
      line-height: 20px;
      font-size: 15px;
      margin-bottom: -5px;
    }

    h4 {
      color: #303030;
      margin-bottom: -10px;
      font-size: 16px;
      color: #6ca714;
    }

    h2 {
      color: #474747;
      line-height: 20px;
      font-size: 20px;
    }
  }

  .publication-cover {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  h3 {
    text-align: left;
    color: #303030;
  }

  hr {
    border: 1px solid rgba(128, 128, 128, 0.158);
  }

  a {
    text-decoration: none;
    color: #6ca714;
    &:hover {
      text-decoration: underline;
    }
  }

  .creds {
    width: 95%;
    margin: auto;

    p {
      font-weight: 400;
    }
  }

  .publications {
    display: flex;
    flex-direction: row;
    flex: auto;
    justify-content: space-between;

    p {
      padding-right: 15px;
    }

    article {
      width: 90%;
    }
  }
}
</style>
