<template>
  <body>
    <main>
      <div class="">
        <div class="database-header__content">
          <h1>
            Global Database of Transboundary Conservation Areas
            <span>(TBCAs)</span>
          </h1>
          <p>
            This database of Transboundary Conservation Areas (TBCAs) is a
            comprehensive knowledge system integrating TBCA site details,
            geospatial information, publications and media.
          </p>
          <a href="/legalDocs">
            <Button label="Search TBCA Knowledge System" />
          </a>
        </div>
      </div>
    </main>

    <div class="container database__categories">
      <h1>Explore Transboundry Conservation Areas by...</h1>
      <div class="content">
        <div>
          <a href="/profiles"> <h3>Region</h3></a>
        </div>
        <div>
          <h3>Borders (km)</h3>
        </div>
        <div>
          <h3>Country</h3>
        </div>
        <div>
          <h3>Individual TBCAs</h3>
        </div>
      </div>
    </div>

    <div class="container gmap">
      <GoogleMap />
    </div>

  </body>
</template>

<script>
// import Map from "@/components/TBCADatabases/Map.vue";
import Button from "@/components/Button.vue";
import GoogleMap from "../../components/TBCADatabases/GoogleMap.vue";


export default {
  components: {
    Button,
    GoogleMap
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 600px) {
  * {
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body {
    main .database-header__content{
      width: 100%;
      padding: 10px;
    }
    .database__categories {
      width: auto;
      .content {
        display: flex;
        flex-direction: column;

        h3 {
          margin-bottom: 10px;
        }
      }

    }

    .gmap {
      width: auto;
    }

    main .map-content {
      display: flex;
      flex-flow: column;
    }

    main .map-content-flex-sec-1 {
      width: 100%;
    }

    main .map-content-flex-sec-2 {
      width: 50%;
    }

    main .main-button {
      margin-bottom: 2rem;
    }
  }
}

body {
  margin-bottom: 20px;
  main {
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
      url("../../assets/cover.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 2rem 0 5rem 0;
    background-position: center;
  }

  .database-header__content {
    width: 70%;
    h1 {
      font-weight: 400;
      font-size: 2.25rem !important;
      color: #fff;
      line-height: 3.2rem;
    }

    p {
      color: #fff;
      font-size: 1rem;
      line-height: 1.5rem;
      letter-spacing: 1px;
    }
  }

  .database__categories {
    position: relative;
    top: -3rem;
    background: #fff;
    border-radius: 0.3rem;
    text-align: center;

    h1 {
      font-weight: 400;
      font-size: 2.25rem !important;
      padding: 2rem 0 0 0;
    }

    .content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 90%;
      margin: auto;

      h3 {
        padding: 4rem;
        text-align: center;
        background-color: #bcef7444;

        border-radius: 0.4rem;
      }
    }
  }
}
</style>
