<template>
  <div>
    <a href="/" @click.prevent="show()"> REGISTER </a>

    <modal class="modal-cover" name="m-register" @opened="opened" :height="500">
      <div class="form-login">
        <h1>REGISTER TO ACCESS THE DATABASE</h1>
        <TinLine />
        <br />
        <form action="">
          <input
            type="text"
            ref="email"
            @keydown.shift.tab.prevent=""
            placeholder="Full name."
          />
          <br /><br />
          <input
            type="text"
            ref="email"
            @keydown.shift.tab.prevent=""
            placeholder="Email address"
          />
          <br /><br />
          <input
            type="text"
            ref="email"
            @keydown.shift.tab.prevent=""
            placeholder="Password"
          />
          <br /><br />
          <input
            type="text"
            ref="email"
            @keydown.shift.tab.prevent=""
            placeholder="Nationality"
          />
          <br /><br />
          <input type="text" placeholder="Occupation" />
          <br /><br />
          <a href="/databases">
            <Button :isFullWidth="false" label="REGISTER" width="25%"></Button>
          </a>
        </form>

        <div class="sign-up-tag">
          <p>Already have an account?</p>
          <p>
            <a @click.prevent="showLogin" @keydown.tab.exact.prevent="" href="/"
              >LOGIN</a
            >
          </p>
        </div>
        <br /><br /><br />
      </div>
    </modal>
  </div>
</template>

<script>
import Button from "@/components/Button";
export default {
  components: {
    Button,
  },

  methods: {
    show() {
      this.$modal.show("m-register");
    },

    showLogin() {
      this.$modal.show("mlogin");
      this.$modal.hide("m-register");
    },

    hide() {
      this.$modal.hide("m-register");
    },

    opened() {
      this.$refs.email.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 900px) {
  .modal-cover {
    border: 1px solid red;
    min-width: 100% !important;
  }
  .form-login {
    padding: 2rem;
    min-width: 100% !important;
  }
}

.sign-up-tag {
  display: flex;
  justify-content: start;

  p {
    margin-right: 50px;
  }
}

.form-login {
  padding: 2rem;
  input {
    font-family: "Raleway", sans-serif;
    width: 60%;
    height: 40px;
    border: 1px solid rgba(106, 106, 106, 0.5);
    border-radius: 5px;
    padding: 0 20px;
    color: rgb(106, 106, 106);
    font-size: 13px;
  }
}
</style>
