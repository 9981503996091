<template>
  <body>
    <main id="app" class="container">
      <v-mapboxgl
        :id="mapId"
        :access-token="accessToken"
        :height="mapHeight"
        :width="mapWidth"
        :gl-center="mapCenter"
        :gl-controls="mapControls"
        :gl-zoom="mapZoom"
        :gl-style="mapStyle"
        :gl-bearing="mapBearing"
        :gl-pitch="mapPitch"
        :gl-popups="mapPopups"
      >
      </v-mapboxgl>
    </main>
  </body>
</template>

<script>
import vMapboxgl from "vue2-mapboxgl-component";

export default {
  components: {
    vMapboxgl,
  },

  data() {
    return {
      accessToken:
        "pk.eyJ1IjoibXVzYWthbm5laCIsImEiOiJja25yNmE5b3kwbGJlMm9vMTFoaTYxMXoyIn0.LQU6a4E2xHjw9KFR_m4MFw",
      mapId: "map",

      mapControls: {
        navigation: {
          enabled: true,
          options: {},
        },
      },

      methods: {
        todek() {
          console.log(this.accessToken);
        },
      },

      mapZoom: {
        value: 8,
      },
    };
  },
};
</script>
<style lang="scss" scoped>
body {
  margin-top: 3rem;
}
</style>
