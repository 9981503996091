<template>
  <body class="container body-bottom_space-added">
    <Captions caption="Meet the Team"></Captions>

    <main>
      <div>
        <img src="../../assets/elaine.png" alt="" />
        <div class="profile__description">
          <Team name="Elaine (Lan Yin) Hsiao" role=" Principal Investigator" />
          <a href="/elaine">
            <Button label="View Profile" width="50%" />
          </a>
        </div>
      </div>

      <div>
        <img src="../../assets/kanneh.png" alt="" />
        <div class="profile__description">
          <Team name="Musa Selekie Kanneh" role="Software Developer" />
          <a href="/musa">
            <Button label="View Profile" width="50%" />
          </a>
        </div>
      </div>

      <div>
        <img src="../../assets/dorine.png" alt="" />
        <div class="profile__description">
          <Team name="Dorine Mutaganzwa" role="Research Assistant" />
          <a href="/dorine">
            <Button label="View Profile" width="50%" />
          </a>
        </div>
      </div>

      <div>
        <img src="../../assets/protais.png" alt="" />
        <div class="profile__description">
          <Team name="Protais Seshaba" role="Research Assistant" />
          <a href="/protais">
            <Button label="View Profile" width="50%" />
          </a>
        </div>
      </div>

      <div>
        <img src="../../assets/jos.png" alt="" />
        <div class="profile__description">
          <Team name="Joseline Barakagwira" role="Research Assistant" />
          <a href="/joseline">
            <Button label="View Profile" width="50%" />
          </a>
        </div>
      </div>

      <div>
        <img src="../../assets/jos.png" alt="" />
        <div class="profile__description">
          <Team name="Joseline Barakagwira" role="Research Assistant" />
          <a href="/joseline">
            <Button label="View Profile" width="50%" />
          </a>
        </div>
      </div>
    </main>

    <br />
    <br />
    <Captions caption="Meet the Advisors"></Captions>
    <main class="advisors__content">
      <div>
        <img class="" src="../../assets/dan.png" alt="" />
        <div class="profile__description">
          <Mentors name="Dan Brockington" role="Primary Advisor" />
          <a href="/dan">
            <Button label="View Profile" width="50%" />
          </a>
        </div>
      </div>

      <div>
        <img class="" src="../../assets/rosaleen.jpg" alt="" />
        <div class="profile__description">
          <Mentors name="Rosaleen Duffy" role="Primary Advisor" />
          <a href="/rosaleen">
            <Button label="View Profile" width="50%" />
          </a>
        </div>
      </div>

      <div>
        <img class="" src="../../assets/rosaleen.jpg" alt="" />
        <div class="profile__description">
          <Mentors name="Beth Kaplin" role="Advisor" />
          <a href="/beth">
            <Button label="View Profile" width="50%" />
          </a>
        </div>
      </div>
    </main>
  </body>
</template>

<script>
import Team from "@/components/Models/Team.vue";
import Mentors from "@/components/Models/Mentors.vue";
import Button from "@/components/Button.vue";
import Captions from "@/components/Captions.vue";

export default {
  components: {
    Button,
    Captions,
    Mentors,
    Team,
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 900px) {
  * {
    display: grid;
    grid-template-columns: 1fr !important;
    width: 100% !important;
    margin: 0 !important;
  }

  img {
    width: 90% !important;
    margin: auto !important;
  }

  a {
    padding-bottom: 1rem !important;
    padding-left: 7rem !important;
    width: 100% !important;
    text-decoration: none;
  }
}

main {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  object-fit: contain;

  img {
    width: 90%;
    margin-left: 5%;
    margin-top: 2rem;
    border-radius: 20rem;
    padding: 0.5rem;
    border: 1px solid #ddd;
  }

  div {
    margin: auto;
    width: 100%;

    .profile__description {
      width: 90%;
      text-align: center;
    }
  }
}
</style>
