<template>
  <body class="container">
    <div>
      <h1>Upcomming events</h1>
      <p>List of upcomming events</p>
    </div>
    <main>
      <div>
        <h2></h2>
      </div>
    </main>
  </body>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
body {
  main {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
</style>
