import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueCarousel from "vue-carousel";
import VueGeolocation from "vue-browser-geolocation";
import VModal from "vue-js-modal";
import * as VueGoogleMaps from "vue2-google-maps";
import VueMeta from "vue-meta";
import "mapbox-gl/dist/mapbox-gl.css";

Vue.use(VueMeta);
Vue.use(VModal);

Vue.use(VueGoogleMaps, {
  load: {
    // key: 'AIzaSyBE9gGwZe6OI0fH-dn_qW7zi9nxcZ6mjcA',
  },
});

Vue.config.productionTip = false;
Vue.use(VueGeolocation);
Vue.use(VueCarousel);
Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
