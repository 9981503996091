<template>
  <div>
    <button>
      <p>{{ label }} <i class="fa fa-download"> </i></p>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  font-size: 16px;
  background-color: #649c0f;
  color: #ffffff;
  width: 20%;
  border-radius: 4px;
  border-style: none;
  outline: none;
  order: 2;
  height: 3.5rem;
  cursor: pointer;
  text-align: center;

  p,
  i {
    color: #ffffff;
    letter-spacing: 1px;
  }

  &:hover {
    background: #49720a;
    transition: 1s ease;
    color: #ffffff;
  }
}
</style>
