<template>
  <body class="add-padding team-member__profile">
    <main class="container">
      <aside>
        <br />
        <img class="logo" src="../../assets/dan.png" alt="" />
        <div class="social-medias__team-profile">
          <a href="https://www.facebook.com/profile.php?id=100007245843942">
            <i class="fab fa-facebook"></i
          ></a>
          <i class="fab fa-instagram"></i>
          <a href="https://www.linkedin.com/in/dan-brockington-9aa07513/">
            <i class="fab fa-linkedin"></i
          ></a>

          <a href="https://twitter.com/MusaSKanneh6">
            <i class="fab fa-twitter"></i
          ></a>
        </div>
      </aside>

      <article>
        <TeamMemberProfile name="Dan Brockington" />
        <p>
          <strong>Dan Brockington</strong> is a co-director of the Sheffield
          Institute for International Development
          <a href="http://siid.group.shef.ac.uk/">(SIID)</a>, at the University
          of Sheffield. He trained as an anthropologist at UCL, where he wrote
          his PhD under Kathy Homewood’s supervision. He then worked at the
          Geography Departments of the Universities of Cambridge (on a post-doc
          with Bill Adams) and Oxford before moving to Manchester (at the
          Institute for Development Policy and Management, now Global
          Development Institute) in 2005 and then Sheffield in 2015.
        </p>
        <p>
          Most of his research has been in Tanzania, where he has worked on
          livelihood change, natural resource governance, microfinance and
          institutional performance. But he has also worked in South Africa,
          Australia, New Zealand and India. His broader interests include work
          on global overviews of the social impacts of protected areas, media
          and conservation, sectoral studies of NGOs and development data. He
          has also worked on celebrity and development, based largely on
          research in the UK.
        </p>
        <p>
          Dan is happiest conducting long term field research in remote areas of
          East Africa but he also learns much from studying organizations and
          the occasional plush fundraising event. His books are:
          <a
            href="https://www.amazon.co.uk/Fortress-Conservation-Preservation-Mkomazi-Tanzania/dp/0852554176/ref=sr_1_4?s=books&ie=UTF8&qid=1294244981&sr=1-4"
            >Fortress Conservation </a
          >(2002),
          <a
            href="https://www.amazon.co.uk/Nature-Unbound-Present-Future-Protected/dp/1844074404/ref=ntt_at_ep_dpt_2"
            >Nature Unbound</a
          >
          (with Rosaleen Duffy and Jim Igoe, 2008),
          <a
            href="https://www.amazon.co.uk/Celebrity-Environment-Wealth-Power-Conservation/dp/1842779745/ref=ntt_at_ep_dpi_1"
            >Celebrity and the Environment</a
          >
          (2009) and
          <a
            href="https://www.routledge.com/Celebrity-Advocacy-and-International-Development/Brockington/p/book/9780415707213"
            >Celebrity Advocacy and International Development</a
          >
          (2014).
        </p>

        <a href="">
          <DownloadCv label="Download CV" />
        </a>
      </article>
    </main>
  </body>
</template>

<script>
import DownloadCv from "@/components/Models/DownloadCv.vue";
import TeamMemberProfile from "@/components/Models/TeamMemberProfile.vue";

export default {
  components: { DownloadCv, TeamMemberProfile },
};
</script>

<style lang="scss" scoped>
@media (max-width: 600px) {
  body {
    width: 100%;
    padding: 10px;

    main {
      width: auto;
      flex-direction: column !important;
    }

    p {
      width: 100% !important;
    }
  }
}
</style>
