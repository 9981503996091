<template>
  <body class="add-padding">
    <Captions caption="Recent Publications"></Captions>
    <main class="container">
      <aside>
        <h1>Related <span>Topics</span></h1>
        <hr />
      </aside>
      <article>
        <h3>
          Protecting Protected Areas <em><span>in Bello</span></em
          >: Learning From Institutional Design and Conflict Resilience in the
          Greater Virunga and Kidepo Landscapes
        </h3>
        <p>
          It has often been cited that major armed conflicts (>1,000 casualties)
          afflicted two-thirds (23) of the world’s recognized biodiversity
          hotspots between 1950 and 2000. In 2011, the International Law
          Commission (ILC) included in its long-term work program Protection of
          the Environment in Relation to Armed Conflict. This led to the
          adoption of twenty-eight Draft Principles, including designation of
          protected zones where attacks against the environment are prohibited
          during armed conflict. Protected zone designations apply to places of
          major environmental and cultural importance, requiring that they
          “[...] shall be protected against any attack, as long as it does not
          contain a military objective.” Most research on armed conflict and
          protected areas has focused on impacts to wildlife and less on how to
          protect these natural habitats from the ravages of armed conflict.
        </p>
        <br />
        <p>
          This article highlights some of the gaps in the ILC Draft Principles
          towards protecting protected zones in bello. It uses transboundary
          protected areas (TBPAs) formalized through multilateral agreements to
          illustrate challenges on the ground. TBPAs are internationally
          designated “[...] protected areas that are ecologically connected
          across one or more international boundaries [...]” and sometimes even
          established for their promotion of peace (i.e., Parks for Peace).
          There is little legal research on how to design TBPA agreements for
          conflict resilience, conflict sensitivity, and ultimately positive
          peace. The research draws from two case studies in Africa’s Great Rift
          Valley: the Greater Virunga Landscape (GVL) between the Democratic
          Republic of the Congo (DRC), Rwanda, and Uganda, and the Kidepo
          Landscape, which forms part of the broader Landscapes for Peace
          initiative between South Sudan and Uganda. Both suffer from armed
          conflicts of various types and present two of the only TBPAs in the
          world that have incorporated environmental peacebuilding into their
          transboundary agreements. The case studies illustrate different
          approaches to TBPA design and the pros and cons of each modality in
          the context of conflict resilience and conflict sensitivity. This
          guides us on how to better protect protected areas in bello, ensuring
          that protected zones endure on the ground and not just in principle.
        </p>
        <br />
        <a href="https://www.gojil.eu/101-abstract-hsiao">
          <Button
            style="margin-left: 22px;"
            :isFullWidth="false"
            label="Read More"
            width="25%"
          ></Button>
        </a>
      </article>
    </main>


    <MoreWritings />
  </body>
</template>

<script>
import Captions from "@/components/Captions";
import Button from "@/components/Button";
import MoreWritings from "@/components/PersonalBlog/MoreWritings";

export default {
  components: {
    Captions,
    Button,
    MoreWritings,
  },
};
</script>

<style lang="scss" scoped>

@media (max-width: 600px) {
  
  body {
    padding: 10px;

    .container {
      display: flex;
      flex-direction: column;
    }

    .container {
      width: 100%;
      word-wrap: break-word;
    }
  }
}

body {
  background-color: #fbfbfb;

  main {
    display: flex;
    flex-direction: row;
    flex: auto;

    aside {
      flex: 1;
      background-color: #fff;

      h1 {
        text-align: center;
      }

      span {
        color: #6ca714;
      }
      img {
        width: 100%;
      }
    }

    article {
      background-color: #fff;

      flex: 3;

      span {
        color: #6ca714;
      }
      p {
        width: 95%;
        margin: auto;
      }

      h3 {
        width: 90%;
        margin-left: 3%;
      }
    }
  }
}
</style>
