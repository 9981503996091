<template>
  <body>
    <div class="photo-info">
      <p>
        If you are interested in using these images, you can contact us
        <a href="/contact">HERE</a>.
      </p>
      <p>
        This work is licensed under a
        <a href="http://creativecommons.org/licenses/by-nc-sa/4.0/"
          >Creative Commons Attribution-NonCommercial-ShareAlike 4.0
          International License.</a
        >
      </p>
    </div>

    <carousel :perPage="4">
      <slide class="car1">
        <div><img class="" src="../../assets/imga.png" alt="" /> <br /></div>
        <div class="cover">
          <div class="location-cover">
            <small
              ><i class="fa fa-map-marker-alt"></i>
              New York
            </small>
            <div>
              <small><i class="fas fa-camera-retro"></i></small> &nbsp;
              <small>Elaine Lan Yin Hsiao</small>
            </div>
          </div>
        </div>
      </slide>

      <slide class="car1">
        <div><img class="" src="../../assets/imgc.png" alt="" /> <br /></div>
        <div class="cover">
          <div class="location-cover">
            <small
              ><i class="fa fa-map-marker-alt"></i>
              New York
            </small>
            <div>
              <small><i class="fas fa-camera-retro"></i></small> &nbsp;
              <small>Elaine Lan Yin Hsiao</small>
            </div>
          </div>
        </div>
      </slide>

      <slide class="car1">
        <div><img class="" src="../../assets/imgb.png" alt="" /> <br /></div>
        <div class="cover">
          <div class="location-cover">
            <small
              ><i class="fa fa-map-marker-alt"></i>
              New York
            </small>
            <div>
              <small><i class="fas fa-camera-retro"></i></small> &nbsp;
              <small>Elaine Lan Yin Hsiao</small>
            </div>
          </div>
        </div>
      </slide>

      <slide class="car1">
        <div><img class="" src="../../assets/pic.png" alt="" /> <br /></div>
        <div class="cover">
          <div class="location-cover">
            <small
              ><i class="fa fa-map-marker-alt"></i>
              New York
            </small>
            <div>
              <small><i class="fas fa-camera-retro"></i></small> &nbsp;
              <small>Elaine Lan Yin Hsiao</small>
            </div>
          </div>
        </div>
      </slide>

      <slide class="car1">
        <div><img class="" src="../../assets/pic.png" alt="" /> <br /></div>
        <div class="cover">
          <div class="location-cover">
            <small
              ><i class="fa fa-map-marker-alt"></i>
              New York
            </small>
            <div>
              <small><i class="fas fa-camera-retro"></i></small> &nbsp;
              <small>Elaine Lan Yin Hsiao</small>
            </div>
          </div>
        </div>
      </slide>

      <slide class="car1">
        <div><img class="" src="../../assets/pic.png" alt="" /> <br /></div>
        <div class="cover">
          <div class="location-cover">
            <small
              ><i class="fa fa-map-marker-alt"></i>
              New York
            </small>
            <div>
              <small><i class="fas fa-camera-retro"></i></small> &nbsp;
              <small>Elaine Lan Yin Hsiao</small>
            </div>
          </div>
        </div>
      </slide>
    </carousel>
  </body>
</template>

<script>
export default {
  components: {},

  props: {
    thumbnail: {
      type: String,
      required: true,
    },

    image: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 900px) {
  * {
    display: none !important;
  }
}

body {
  width: 90%;
  margin: auto;
  padding: 3% 0 6% 0;

  a {
    text-decoration: none;
    color: #6ca714;

    &:hover {
      text-decoration: underline;
    }
  }

  .cover {
    width: 80%;
    margin: auto;
  }

  .location-cover {
    margin-top: 10px;
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .photo-info {
    text-align: center;
    color: #474747;
    padding: 1.5rem 0;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.015em;

    a {
      color: #6ca714;
      font-size: 14px;
      font-weight: 500;
    }
  }
}

.car1 {
  align-items: center;
  margin: auto;
  width: 90%;

  i {
    color: #6ca714;
  }

  .img-description {
    color: #474747;
    margin-top: 5px;
  }

  img {
    object-fit: contain;
    width: 80%;
    margin-left: 10%;
  }

  img:hover {
    transform: scale(1.01);
    transition: all 0.5s ease-in-out;
  }

  small {
    font-weight: 500;
    font-size: 12px;
    color: #404040d2;
  }
}
</style>
