<template>
  <body>
    <div class="tbce-database">
      <a href="/" @click.prevent="show()"> TBCA DATABASE</a>
    </div>
    <modal class="modal-cover" name="mlogin" @opened="opened" :height="auto">
      <div class="form__login">
        <h1>Access the TBCA Database</h1>
        <form action="">
          <input
            type="text"
            ref="email"
            @keydown.shift.tab.prevent=""
            placeholder="Enter your username."
          />
          <br /><br />
          <input type="text" placeholder="Enter your password." />
          <br /><br />
          <a class="login-modal" href="/database">Login</a>
        </form>

        <div class="sign-up-tag">
          <p>Don't have an account?</p>
          <p>
            <a
              @click.prevent="showRegister"
              @keydown.tab.exact.prevent=""
              href="/"
            >
              <ModelRegister
            /></a>
          </p>
        </div>
      </div>
    </modal>
  </body>
</template>

<script>
import ModelRegister from "@/components/PersonalBlog/ModelRegister";
export default {
  components: {
    ModelRegister,
  },

  methods: {
    show() {
      this.$modal.show("mlogin");
    },

    showRegister() {
      this.$model.show("m-register");
      this.$modal.hide("mlogin");
    },

    hide() {
      this.$modal.hide("mlogin");
    },

    opened() {
      this.$refs.email.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 600px) {
  * {
    margin-top: 0.3rem !important;
    padding: 0 2.5rem;
    width: 100%;
    font-weight: 500 !important;
    letter-spacing: 2px !important;
    font-size: 1.1rem !important;
  }

  .form__login {
    padding: 2rem;
  }
}

.tbce-database {
  background-color: #649c0f;
  padding: 1rem 2rem;
  box-shadow: inset 0 -0.6em 1em -0.35em rgba(0, 0, 0, 0.17),
    inset 0 0.6em 2em -0.3em rgba(255, 255, 255, 0.15),
    inset 0 0 0em 0.05em rgba(255, 255, 255, 0.12);
  text-align: center;
  border-radius: 3px;
  margin: 1rem 0;
  font-size: 14px;
}

.modal-cover {
  background-color: green;
  z-index: 2;
}

li,
li a {
  font-size: 14px;
  letter-spacing: 1px;
  color: #fff;
  cursor: pointer;
  list-style: none;
  text-decoration: none;
  text-decoration: none;
  font-weight: 500;

  a {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
  }
}

.login-modal {
  border: 1px solid #6ca714;
  color: #6ca714;
  border-radius: 5px;
  padding: 10px 45%;

  &:hover {
    color: #fff;
    background-color: #6ca714;
  }
}

.sign-up-tag {
  display: flex;
  justify-content: space-evenly;
}

.form__login {
  background-color: #ffffff;
  border-radius: 0 1rem 1rem 0;
  padding: 2rem;

  input {
    width: 100%;
    height: 40px;
    border-radius: 2px;
    color: rgb(106, 106, 106);
    font-size: 13px;
  }
}
</style>
