<template>
  <body class="container">
    <main class="add-padding">
      <div class="sec-one-cover">
        <aside>
          <div class="">
            <h2>Attributes</h2>
            <div class="attributes-cover">
              <h3>Name</h3>
              <p>{{ Name }}</p>
            </div>
            <div class="attributes-cover-2">
              <h3>Designation</h3>
              <p>{{ Designation }}</p>
            </div>
            <div class="attributes-cover">
              <h3>IUCN Management Category</h3>
              <p>{{ IUCNManagementCategory }}</p>
            </div>
            <div class="attributes-cover-2">
              <h3>Status</h3>
              <p>{{ Status }}</p>
            </div>
            <div class="attributes-cover">
              <h3>Status Year</h3>
              <p>{{ StatusYear }}</p>
            </div>
            <div class="attributes-cover-2">
              <h3>Governance Type</h3>
              <p>{{ GovernanceType }}</p>
            </div>
            <div class="attributes-cover">
              <h3>Management Authority</h3>
              <p>{{ ManagementAuthority }}</p>
            </div>
            <div class="attributes-cover-2">
              <h3>Contact Information</h3>
              <p>{{ ContactInformation }}</p>
            </div>
            <div class="attributes-cover">
              <h3>International Criteria</h3>
              <p>{{ InternationalCriteria }}</p>
            </div>
          </div>
        </aside>
        <article>
          <div class="map-cover">
            <h2>Management Effectiveness Evaluations</h2>
            <div class="attributes-cover">
              <h3>Management Effectiveness Evaluations</h3>
              <p>{{ ManagementEffectivenessEvaluations }}</p>
            </div>
            <div class="attributes-cover-2">
              <h3>Affiliations</h3>
              <p v-if="countReference()">{{ Affiliations }}</p>
            </div>
          </div>
        </article>
      </div>
    </main>
  </body>
</template>

<script>
export default {
  components: {},

  data() {
    return {
      Name: "Sapo National Park",
      Designation: "National Park",
      IUCNManagementCategory: "II",
      Status: "Protected",
      StatusYear: "2005",
      Location: "Liberia",
      GovernanceType: "Not Reported",
      ManagementAuthority: "Sarawak Forestry Corporation (SFC)",
      ContactInformation: "Not Reported",
      InternationalCriteria: "Not Applicable",

      /**
       * TBCA management information
       */
      ManagementEffectivenessEvaluations: "No information available",
      Affiliations: "No information available",
      References: ["Reference one"],
    };
  },

  methods: {
    showPage() {
      return this.pageOne;
    },

    countReference() {
      if (isNaN(this.References)) {
        return null;
      }
      for (let i = 0; i < this.Reference.length; i++) {
        return this.References.length;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
body {
  main {
    h1,
    h3,
    p {
      color: #ffffff;
    }

    h2 {
      color: #ffffff;
      font-weight: 400;
      font-size: 20px;
    }
    p {
      margin: 0;
      padding-left: 1rem;
    }

    h3 {
      font-size: 16px;
      letter-spacing: 0.5px;
      margin: 0;
    }
    .attributes-cover-2 {
      background: #30303093;
      padding: 1rem;
      display: flex;
    }
    .attributes-cover {
      background: #303030;
      padding: 1rem;
      display: flex;
    }
    .sec-one-cover {
      display: grid;
      grid-template-columns: 1fr 1fr;

      article {
        background-color: #242424d2;
        padding: 1rem;
        width: 100%;
        margin-right: 5%;
      }

      aside {
        background-color: #242424d2;
        width: 95%;
        padding: 1rem 1.5rem;
      }
    }
  }
}
</style>
