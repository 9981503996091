<template>
  <body>
    <div class="about">
      <Captions caption="About The Research Project Fellowship"> </Captions>
      <br /><br />
      <Tabs>
        <Tab name="The Global Challanges Fellowship" selected="true">
          <div class="content">
            <aside>
              <img class="sheffield" src="../../assets/gc.png" alt="" />
            </aside>
            <article>
              <p>
                As a
                <a
                  href="https://www.sheffield.ac.uk/staff/global-challenges-research-fellowships/elaine-hsiao"
                  >Global Challenges Fellow,</a
                >
                I will be building on previous fieldwork in the Albertine Rift
                region beginning in 2010, allowing me to go deeper with critical
                questions about the interstitial zones between countries and
                especially between communities and parks or protected areas,
                where the greatest social, political, and development challenges
                can arise.
              </p>
              <p>
                The
                <a
                  href="https://www.sheffield.ac.uk/staff/global-challenges-research-fellowships"
                  >Global Challenges Fellowship</a
                >
                at the University of Sheffield is a 24-month independent
                post-doctoral programme of research that addresses global
                challenges through 2-way partnerships with institutions based in
                DAC-listed countries. It is funded by the UK’s
                <a
                  href="https://www.ukri.org/our-work/collaborating-internationally/global-challenges-research-fund/"
                  >Global Challenges Research Fund</a
                >
                and seeks to support capacity-building between partner
                institutions.
              </p>
              <a href="/project">
                <Button
                  :isFullWidth="false"
                  label="Read More"
                  width="150px"
                ></Button>
              </a>
            </article>
          </div>
        </Tab>
        <Tab name="The Research Project">
          <div class="content">
            <aside>
              <img src="../../assets/pic1.png" alt="" />
            </aside>
            <article>
              <strong>
                “From disrupted to emblematic ICCAs: Revitalizing community
                conservation in <br />
                transboundary landscapes of the Albertine Rift”</strong
              >
              <br /><br />
              <p>
                The Albertine Rift represents a confluence of all of the world’s
                peace and development challenges in ecologically unique and
                unforgettably beautiful landscapes with an equally rich
                diversity of cultures. This project is premised on the belief
                that it is possible to build positive peace between people and
                with nature alongside regenerative development without
                compromising cultural heritage in the Albertine, or anywhere.
              </p>
              <a href="/writings">
                <Button
                  class="mobile-style-1"
                  style="margin-left: 0"
                  :isFullWidth="false"
                  label="Read More"
                  width="150px"
                ></Button>
              </a>
            </article>
          </div>
        </Tab>
      </Tabs>
    </div>

    <section class="tbca-databases">
      <h2>
        The Transboundry Conservation Areas <br />
        (TBCA) Database.
      </h2>
      <p>
        Rwanda has four national parks, all of which are part of larger
        transboundary ecosystems.
      </p>
      <p>
        This is a database of Transboundary Conservation Areas (TBCAs) <br />
        from around the world.
      </p>
      <br />
      <Button
        :isFullWidth="false"
        label="Explore Database"
        width="150px"
        class="mobile-style"
      ></Button>
    </section>
  </body>
</template>

<script>
import Button from "@/components/Button.vue";
import Captions from "@/components/Captions.vue";
import Tab from "@/components/Tabs/Tab.vue";
import Tabs from "@/components/Tabs/Tabs.vue";
export default {
  components: {
    Button,
    Captions,
    Tab,
    Tabs,
  },
};
</script>

<style lang="scss" scoped>
body {
  a {
    text-decoration: none;
    color: #6ca714;

    &:hover {
      text-decoration: underline;
    }
  }

  .about {
    position: relative;
    top: -8vh;
    background: #fff;
    border-radius: 5px 5px 0 0;
    max-width: 1200px;
    padding-top: 5px;
    margin: auto;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    padding: 2rem;
  }

  .content {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 2.5rem;

    p {
      padding-bottom: 0.5rem;
      width: 95%;
    }

    img {
      width: 100%;
    }
  }
}

.tbca-databases {
  margin-top: 0;
  padding: 10vh 0;
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url("../../assets/pa.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  background-position: center;
  text-align: center;

  i {
    font-size: 40px;
    color: #6ca714;
  }

  h2,
  p {
    margin: 1rem 0;
  }

  p {
    color: #fff;
    line-height: 24px;
    font-weight: normal;
    letter-spacing: 0.015em;
    font-weight: 500;
    font-size: 18px;
  }

  h2 {
    color: #fff;
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 0.015em;
  }
}
</style>
