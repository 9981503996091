<template>
  <body>
    <main class="container">
      <div class="google-map">
        <GmapMap
          :center="myCoordinates"
          :zoom="zoom"
          style="width: 100%; height: 600px;"
          ref="mapRef"
          @dragend="handleDrag"
        ></GmapMap>
      </div>
      <!-- <MapLayers /> -->
    </main>
  </body>
</template>
<script>
// import MapLayers from "@/components/TBCADatabases/MapLayers.vue";
export default {
  // components: { MapLayers },
  data() {
    return {
      map: null,
      myCoordinates: {
        lat: 0,
        lng: 0,
      },
      zoom: 7,
    };
  },

  created() {
    if (localStorage.center) {
      this.myCoordinates = JSON.parse(localStorage.center);
    } else {
      this.$getLocation({})
        .then((coordinates) => {
          this.myCoordinates = coordinates;
        })
        .catch((error) => alert(error));
    }
    if (localStorage.zoom) {
      this.zoom = parseInt(localStorage.zoom);
    }
  },
  mounted() {
    this.$refs.mapRef.$mapPromise.then((map) => (this.map = map));
  },

  methods: {
    handleDrag() {
      let center = {
        lat: this.map.getCenter().lat(),
        lng: this.map.getCenter().lng(),
      };
      let zoom = this.map.getZoom();
      localStorage.center = JSON.stringify(center);
      localStorage.zoom = zoom;
    },
  },

  computed: {
    mapCoordinates() {
      if (!this.map) {
        return {
          lat: 0,
          lng: 0,
        };
      }
      return {
        lat: this.map.getCenter().lat().toFixed(4),
        lng: this.map.getCenter().lng().toFixed(4),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 600px) {
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
}
body {
  main {
    .google-map {
      width: 100%;
      background-color: #303030;
    }
  }
}
</style>
