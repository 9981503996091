<template>
  <body>
    <main class="container-small">
      <div class="search-bar-identity-sec">
        <div class="caption">
          <h2 class="h2-tag"><i class="fa fa-newspaper"></i> TBCA Documents</h2>
        </div>
        <div class="description">
          <p>
            The gateway to transboundry conservation
          </p>
        </div>
      </div>

      <div class="">
        <section class="filters">
          <div class="locations">
            <i class="fa fa-newspaper"></i>
          </div>
          <input
            type="text"
            placeholder="Search for legal documents here"
            label="Search documents"
          />
          <button>Search</button>
        </section>
        <p>
          Please feel free to contact us at ecologicalpeace@gmail.com if you
          have any suggestions or experience any difficulties.
        </p>
      </div>

      
      <div class="add-padding-smaller">
        <button v-on:click="changeSearch()">See advanced search</button>
        <a href="/info">
          <button>See existing results</button>
        </a>
      </div>

      <AdvancedSearchForm v-if="showAdvancedSearch" />
      <p v-else>
        Click the button above to access the advanced search option.
      </p>
    </main>
  </body>
</template>

<script>
import AdvancedSearchForm from "@/components/TBCADatabases/AdvancedSearchForm.vue";

export default {
  components: { AdvancedSearchForm },

  data() {
    return {
      showAdvancedSearch: false,
    };
  },

  methods: {
    changeSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 600px) {
  body {
    margin: auto;
    width: 100%;
    padding: 0.2rem;

    main {
      width: 100%;
      padding: 0.5rem;
    }
  }
}


main {
  padding: 4rem 0 10rem 0;

  .search-bar-identity-sec {
    width: 100%;
    margin: auto;
    justify-content: space-between;
  }

  .filters {
    background-color: #ffffff;
    height: 55px;
    width: 100%;
    //border: 1px solid #649c0fbd;
    border-radius: 5px;
    text-align: right;
    display: flex;
    justify-content: flex-start;
  }

  h2 {
    color: #606060;
  }

  p {
    color: #303030;
    font-size: 13px;
  }

  .caption {
    i {
      color: #649c0f;
    }
  }

  button {
    font-size: 14px;
    background-color: #649c0f;
    color: #ffffff;
    height: 40px;
    width: auto;
    padding: 0 1rem;
    border-radius: 4px;
    border-style: none;
    margin-top: 8px;
    margin-right: 20px;
    margin-left: auto;
    outline: none;
    order: 2;
    cursor: pointer;
  }

  button:hover {
    opacity: 0.9;
  }

  .locations {
    height: 100%;
    width: 10%;
    border-right: 1px solid #649c0fbd;
    order: 0;
    text-align: left;
    margin-left: 5px;

    i {
      margin: 1.1rem;
    }
  }

  input {
    width: 100%;
    height: 50px;
    outline: none;
    border: none;
    padding: 0 20px;
    color: rgb(106, 106, 106);
    font-size: 15px;
  }
}
</style>
