import { render, staticRenderFns } from "./PastEvents.vue?vue&type=template&id=0ba4f11c&scoped=true&"
import script from "./PastEvents.vue?vue&type=script&lang=js&"
export * from "./PastEvents.vue?vue&type=script&lang=js&"
import style0 from "./PastEvents.vue?vue&type=style&index=0&id=0ba4f11c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ba4f11c",
  null
  
)

export default component.exports