<template>
  <div class="cover">
    <div class="inner">
      <div class="Gallery-cover">
        <GalleryCard />
      </div>
    </div>
  </div>
</template>

<script>
import GalleryCard from "@/components/PersonalBlog/GalleryCard.vue";
export default {
  components: {
    GalleryCard,
  },
}; 
</script>

<style lang="scss" scoped>
.Gallery-cover {
  display: flex;

  i {
    margin: auto;
    color: #aa393f;
    padding: 0 10px;
    cursor: pointer;
    font-size: 30px;
    text-align: center;
  }

  i:hover {
    color: #474747;
    transition: 1s ease;
  }
}

.cover {
  h1 {
    text-align: center;
    font-size: 35px;
    font-weight: 600;
  }

  p {
    text-align: center;
  }
}
</style>
