<template>
  <body>
    <main class="container">
      <article>
        <h1>
          Global Challenges
          <span> Fellow</span>
        </h1>
        <h2 class="name-mobile" v-if="isMobile()">
          Elaine (Lan Yin) Hsiao
          <p class="credential">JD, LLM, PhD</p>
        </h2>
        <h2 v-else>
          Elaine <span>(Lan Yin)</span> Hsiao
          <p class="credential">JD, LLM, PhD</p>
        </h2>
        <div>
          <h3>
            Supporting biodiversity, peace and regenerative development through
            rights-empowered environmental cooperation.
          </h3>
        </div>
        <div>
          <a href="">Photo: © Joel Sartore/www.joelsartore.com</a>
        </div>
      </article>

      <aside>
        <carousel :perPage="1">
          <slide class="car1">
            <div class="events">
              <h4>Upcoming Event</h4>
              <i class="fas fa-bullhorn"></i>
            </div>

            <div class="caption">
              <h4>Symposium Panel</h4>
              <p>
                Global Challenges Fellow, Elaine (Lan Yin) Hsiao, will be a
                panelist at the Symposium on “Current Trends in Contemporary
                Peace Research” organized by the Unit for Peace and Conflict
                Studies of the University of Innsbruck, Austria. Global
              </p>
              <p class="date-style-mobile">
                <i class="far fa-calendar-alt"></i> Wed, March 17, 2021
              </p>
              <p class="date-style-mobile">
                <i class="fas fa-clock"></i> 8:00 PM – 10:00 PM CST
              </p>
              <br />
              <button>
                <a
                  href="https://www.eventbrite.ca/e/symposium-current-trends-in-contemporary-peace-research-tickets-133600228881"
                  >Event Webpage and Registration</a
                >
              </button>
            </div>
          </slide>
          <slide class="car1">
            <div class="events">
              <h4>Recent Event</h4>
              <i class="fas fa-newspaper"></i>
            </div>

            <div class="caption">
              <h4>
                Forward Together: Taking Stock of the Environmental
                Peacebuilding Landscape
              </h4>
              <p>
                The Geneva Peacebuilding Platform and Environmental
                Peacebuilding Association are partnering together to facilitate
                a global conversation as part of the ongoing Road to Geneva.
                Recognizing that conceptions and practices in environmental
                peacebuilding are largely intertwined with the places and norms
                where they are constructed, this discussion will engage the
                group in a series of questions about environmental
                peacebuilding, the impact of the COVID-19 pandemic ...
              </p>
              <p class="date-style-mobile">
                <i class="far fa-calendar-alt"></i> Mon Dec 07, 11:00 AM - 12:30
                PM EST / Tue Dec 08, 12:00 AM - 1:30 AM Your local time (1 hour,
                30 min)
              </p>
              <br />
              <button>
                <a href="https://www.gojil.eu/ "
                  >Watch YouTube Panel Discussion:</a
                >
              </button>
            </div>
          </slide>
          <slide class="car1">
            <div class="events">
              <h4>Upcoming Publication</h4>
              <i class="fas fa-bullhorn"></i>
            </div>

            <div class="caption">
              <h4>Journal Article</h4>
              <p>
                An upcoming article by Elaine (Lan Yin) Hsiao and her PhD
                Supervisor, Philippe Le Billon, on “Connecting Peaces: TBCAs and
                the Integration of International, Social, and Ecological Peace”
                in the March 2021 issue of the
                <em> International Journal</em> on World Peace.
              </p>
              <br />
              <button>
                <a href="https://ijwp.org/the-environment-and-peace/"
                  >Folow the article</a
                >
              </button>
            </div>
          </slide>
          <slide class="car1">
            <div class="events">
              <h4>Recent Publication</h4>
              <i class="fas fa-newspaper"></i>
            </div>

            <div class="caption">
              <h4>Online Blog</h4>
              <p>
                Fakunle Aremu, Ousseyni Kalilou and Elaine Hsiao wrote on
                emerging local innovations in response to the challenges of
                COVID and other intersecting crises on environmental
                peacebuilding in Africa for the NewSecurityBeat.
              </p>
              <br />
              <button>
                <a
                  href="https://www.newsecuritybeat.org/2021/02/pan-african-response-covid-19-new-environmental-peacebuilding-emerge/"
                  >Read more</a
                >
              </button>
            </div>
          </slide>
        </carousel>
      </aside>
    </main>
  </body>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 900px) {
  * {
    display: block !important;
    width: 100% !important;
  }

  .name-mobile {
    font-size: 2em;
  }
  h1 {
    font-size: 30px !important;
  }

  main {
    width: 90% !important;
    margin: auto;
  }

  h2 {
    font-size: 1.5em !important;
  }

  aside,
  .quote {
    width: 100% !important;
  }
  .quote {
    font-size: 14px !important;
    br {
      display: none !important;
    }
    a {
      font-size: 10px;
      margin-top: 10px;
    }
  }

  .car1 {
    min-width: 100% !important;
    padding: 0 !important;
    margin: 0 !important;

    .caption {
      width: 95% !important;
      margin: auto !important;

      a {
        width: 50% !important;
      }

      p {
        width: 95% !important;
        margin: auto;
        padding: 0 !important;
        text-align: left;
      }
    }

    .events {
      display: flex !important;
      h4 {
        flex: 2;
      }
      i {
        flex: 1;
      }
      h4,
      i {
        margin: 0;
      }
    }
  }

  .date-style-mobile {
    margin-top: 10px !important;
  }
}

body {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.712)),
    url("../../assets/bg.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  margin: auto;
  opacity: 1;
  background-size: cover;
  z-index: 1;

  .credit {
    a {
      font-size: 11px;
      color: #fff;
    }
    position: absolute;
    bottom: 0px;
  }

  aside {
    background-color: #fbfbfb;
    border-radius: 3px;
    height: 50vh;
    width: 40%;
    margin-bottom: 50px;

    h4 {
      color: #6ca714;
      font-size: 16px;
      font-weight: 400;
    }

    .car1 {
      width: 100%;

      .caption {
        width: 91%;
        margin: auto;
        i {
          color: #6ca714;
          width: 30px;
        }

        h4 {
          text-align: left;
          font-size: 14px;
        }

        p {
          text-align: left;
          font-size: 13px;
          line-height: 18px;
        }

        button {
          border-radius: 4px;
          border: 1px solid #6ca714;
          padding: 0.5rem 1rem;
          letter-spacing: 0.5px;
          background-color: #6ca714;
          font-size: 14px;

          a {
            text-decoration: none;
            color: #ffffff;
            &:hover {
              color: #fff;
              transition: 0.5s ease;
            }
          }
        }
      }
    }
  }

  main {
    display: flex;
    justify-content: space-between;
    flex: auto;
    padding: 14vh 0;
    height: 90vh;

    span {
      color: #6ca714;
    }

    article {
      height: 50vh;
      padding: 3rem 0;
      width: 55%;

      a {
        color: #6ca714;
        text-decoration: none;
        font-size: 12px;

        &:hover {
          text-decoration: underline;
          color: #ffffff;
          transition: 0.5s ease;
        }
      }
    }

    h1,
    h2,
    h3 {
      margin-bottom: 1rem;
    }

    h2 {
      font-size: 35px;
      color: #fbfbfb;
      text-decoration: none;
      font-weight: 700;
      padding: 0.4rem 0;

      p {
        margin-top: 10px;
        font-weight: 600;
        color: #fbfbfb;
        font-size: 20px;
      }
    }

    h1 {
      font-size: 50px;
      opacity: 0.9;
      color: #fbfbfb;
      font-weight: 800;
      transition: 200ms;
      text-decoration: none;
    }

    h3 {
      font-weight: normal;
      font-size: 20px;
      line-height: 150%;
      letter-spacing: 0.015em;
      color: #ffffff;
    }
  }

  .events {
    display: flex;
    align-items: center;
    background-color: #6ca714;
    padding: 16px 20px;

    i {
      font-size: 20px;
      color: #fff;
      margin-left: 10%;
      letter-spacing: 0.5px;
    }

    h4 {
      text-align: center;
      color: #fbfbfb;
      margin: 0;
      font-weight: 700;
      letter-spacing: 0.015em;
      font-size: 20px;
    }
  }
}
</style>
