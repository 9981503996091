<template>
  <body class="add-padding">
    <main class="container">
      <article>
        <div class="media-condent">
          <blockquote class="embedly-card">
            <h3>
              <a
                href="https://l.facebook.com/l.php?u=https%3A%2F%2Fvimeo.com%2F16940715%3Ffbclid%3DIwAR0bfI7ZJ3ayzaB6Nx4GyQ8JoAVAJugUSDHlfigPhdQ8g8b_pM2WvssKR_c&h=AT1-Mx1_7CcBHYn1dJO_zPYk2KS6H4ebrRUX86REDis6ItnTfl-mrmWx5nr6WFOL8OxFJiTFz9E1ELMNHBhdC3VzhMk5oo9vB3xG5ATORHbvD2kbu3K8smCMbFsH"
                >Community Participation in Transboundary Conservation</a
              >
            </h3>
            <p>http://peaceparkexpeditions.org</p>
          </blockquote>
        </div>
        <div class="media-condent">
          <blockquote class="embedly-card">
            <h4>
              <a
                href="https://l.facebook.com/l.php?u=https%3A%2F%2Fvimeo.com%2F62257328%3Ffbclid%3DIwAR0bfI7ZJ3ayzaB6Nx4GyQ8JoAVAJugUSDHlfigPhdQ8g8b_pM2WvssKR_c&h=AT0ojnYaS8dnkjmvYknYzf8qyGlI6k4e8_myeapHnudQaBsvFxGTBpuTQ2EpZAiqSNQ1TuzryWtyLY5t1-_e62Yjocbj3iSkWLK33dY_6K2DIbFRZZOCTA2nm323"
                >Transcending Boundaries: Perspectives from the Central
                Albertine Rift Transfrontier Protected Area Network</a
              >
            </h4>
          </blockquote>
        </div>
        <div class="media-condent">
          <blockquote class="embedly-card">
            <h4>
              <a
                href="https://l.facebook.com/l.php?u=https%3A%2F%2Fvimeo.com%2F62257328%3Ffbclid%3DIwAR0bfI7ZJ3ayzaB6Nx4GyQ8JoAVAJugUSDHlfigPhdQ8g8b_pM2WvssKR_c&h=AT0ojnYaS8dnkjmvYknYzf8qyGlI6k4e8_myeapHnudQaBsvFxGTBpuTQ2EpZAiqSNQ1TuzryWtyLY5t1-_e62Yjocbj3iSkWLK33dY_6K2DIbFRZZOCTA2nm323"
                >Transcending Boundaries: Perspectives from the Central
                Albertine Rift Transfrontier Protected Area Network</a
              >
            </h4>
          </blockquote>
        </div>
        <div class="media-condent">
          <blockquote class="embedly-card">
            <h4>
              <a
                href="https://l.facebook.com/l.php?u=https%3A%2F%2Fvimeo.com%2F62257328%3Ffbclid%3DIwAR0bfI7ZJ3ayzaB6Nx4GyQ8JoAVAJugUSDHlfigPhdQ8g8b_pM2WvssKR_c&h=AT0ojnYaS8dnkjmvYknYzf8qyGlI6k4e8_myeapHnudQaBsvFxGTBpuTQ2EpZAiqSNQ1TuzryWtyLY5t1-_e62Yjocbj3iSkWLK33dY_6K2DIbFRZZOCTA2nm323"
                >Transcending Boundaries: Perspectives from the Central
                Albertine Rift Transfrontier Protected Area Network</a
              >
            </h4>
          </blockquote>
        </div>
        <div class="media-condent">
          <blockquote class="embedly-card">
            <h4>
              <a
                href="https://l.facebook.com/l.php?u=https%3A%2F%2Fvimeo.com%2F62257328%3Ffbclid%3DIwAR0bfI7ZJ3ayzaB6Nx4GyQ8JoAVAJugUSDHlfigPhdQ8g8b_pM2WvssKR_c&h=AT0ojnYaS8dnkjmvYknYzf8qyGlI6k4e8_myeapHnudQaBsvFxGTBpuTQ2EpZAiqSNQ1TuzryWtyLY5t1-_e62Yjocbj3iSkWLK33dY_6K2DIbFRZZOCTA2nm323"
                >Transcending Boundaries: Perspectives from the Central
                Albertine Rift Transfrontier Protected Area Network</a
              >
            </h4>
          </blockquote>
        </div>
        <div class="media-condent">
          <blockquote class="embedly-card">
            <h4>
              <a
                href="https://l.facebook.com/l.php?u=https%3A%2F%2Fvimeo.com%2F62257328%3Ffbclid%3DIwAR0bfI7ZJ3ayzaB6Nx4GyQ8JoAVAJugUSDHlfigPhdQ8g8b_pM2WvssKR_c&h=AT0ojnYaS8dnkjmvYknYzf8qyGlI6k4e8_myeapHnudQaBsvFxGTBpuTQ2EpZAiqSNQ1TuzryWtyLY5t1-_e62Yjocbj3iSkWLK33dY_6K2DIbFRZZOCTA2nm323"
                >Transcending Boundaries: Perspectives from the Central
                Albertine Rift Transfrontier Protected Area Network</a
              >
            </h4>
          </blockquote>
        </div>
      </article>
      <Tweets />
    </main>
    <br />
    <AllEvents />
  </body>
</template>

<script>
import Tweets from "@/components/PersonalBlog/Tweets";
import AllEvents from "@/components/PersonalBlog/AllEvents";

export default {
  components: {
    Tweets,
    AllEvents,
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 600px) {
  * {
    width: auto;
    padding: 10px;
    display: block !important;
  }

  aside {
    width: 100%;
    margin: auto;
    display: block !important;
  }
}
body {
  aside {
    //border: 1px solid red;
  }

  .media-condent {
    border: 1px solid #1010103d;
    padding: 1rem 1.5rem;

    height: 100%;
  }
  main {
    display: grid;
    grid-template-columns: 3fr 1fr;
    article {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }

    aside {
      margin: 0 10px;
    }

    h2 {
      text-align: center;
      color: #fff;
    }
  }
}
</style>
